<div class="font-IBM bgm-white">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" ng-cloak flex class="p-20 login text-center">
    <div fxLayout-padding fxFlex="50" fxFlex-xs="100">
        <div class="p-20" id="SignInSection">
            <div fxLayoutAlign="center center">
              <hr style="border: 1px solid gray; max-width: 350px; width: 100%;"/>
            </div>
            <h3 class="text-center">SIGN In</h3>
            <form name="Login" id="login">
                <div class="m-t-30">
                  <mat-form-field class="w-70 ds-validate font-IBM" [ngStyle.lt-md]="{'width':100+'%'}" appearance="outline">
                    <input  name="username" [(ngModel)]="userInfo.username"
                    [ngModelOptions]="{standalone: true}"  matInput validate noempty="true" placeholder="Username">
                  </mat-form-field>
                </div>
                <div class="">
                  <mat-form-field class="w-70 ds-validate font-IBM" appearance="outline" [ngStyle.lt-md]="{'width':100+'%'}" >
                    <input type="password" [(ngModel)]="userInfo.password"
                    [ngModelOptions]="{standalone: true}"  matInput validate noempty="true" placeholder="Password">
                    </mat-form-field>
                </div>
                <a class="forgot-pass font-IBM color-blackish" id="forgot-pass" (click)="redirectToForgotPassword()" translate="forgot_password"></a>
                <div layout="row" layout-sm="column" class="m-t-20 login-bottom" layout-xs="column" layout-align="start center" layout-align-xs="center start" layout-align-sm="center start" layout-wrap>
                  <button  validate trigger-check="true" type="submit" (success)="loginFunction()" [ngStyle.lt-md]="{'width':100+'%'}" class="w-70 btn-project upper-case bgm-black c-white" color="primary" color="" type="submit">{{'sign_in' | translate}}</button>
                </div>
            </form>
        </div>
      </div>
</div>
</div>






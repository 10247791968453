import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ProductService } from "src/app/components/shared/services/product.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ColorFilter } from "src/app/modals/product.model";
import { FormGroup } from "@angular/forms";
import { ConfigService } from "src/app/components/shared/services/config.service";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UtilityService } from "src/app/components/shared/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { ItemsListService } from "src/app/components/shared/services/itemsList.service";
import { CompanyService } from "src/app/components/shared/services/company.service";
import { Cart1Service } from "src/app/components/shared/services/cart1.service";
import { environment } from "src/environments/environment";
import { LoaderService } from "src/app/components/shared/services/loader.service";
import { ProductSearchPipe } from "src/app/components/shared/pipes/product-search.pipe";
import { SideNavBarService } from "src/app/components/shared/services/sidenavbar.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { UserService } from "src/app/components/shared/services/user.service";
import { AccountService } from "src/app/components/shared/services/account.service";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { VariantSelectionComponent } from "src/app/components/shared/model/variant-selection/variant-selection.component";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: "app-product-no-sidebar",
  templateUrl: "./product-no-sidebar.component.html",
  styleUrls: ["./product-no-sidebar.component.scss"],
  animations: [],
})
export class ProductNoSidebarComponent implements OnInit {
  env;
  searchFormGroup: FormGroup;
  categoryTypeGroup: FormGroup;
  sortByFormGroup: FormGroup;
  productSearchText: string = "";
  public sidenavOpen: boolean = true;
  public animation; // Animation
  public sortByOrder; // sorting
  public page;
  public tagsFilters = [];
  public viewType: string = "grid";
  public viewCol: number = 34;
  public filterForm: FormGroup;
  public colorFilters: ColorFilter[] = [];
  public searchProduct;
  public items: Array<DSProduct> = [];
  public products: Array<DSProduct> = [];
  public allItems = [];
  public featuredCollection: Array<DSProduct> = [];
  userService: UserServiceModal;
  public tags = [];
  public colors = [];
  customCssCheckBox;
  Checkedall: boolean = false;
  Checkedmagnetic: boolean = false;
  CheckedwhatsHot: boolean = false;
  Checkedenrollment: boolean = false;
  Checkedlips: boolean = false;
  Checkedeyes: boolean = false;
  slides = [];
  sortKeys = [];
  selectedCategory: { Category?: string, CategoryId?:(number|string) } = { Category: "all", CategoryId: -1 };
  isAllunselect: boolean;
  regularDistribution = 100 / 3 + "%";
  RegionIDForRequest: number;
  public OrderQuantityCount;
  showNoProductsError: boolean = false;
  showIsLoading: boolean = true;
  filterBySelectedValue = {
    "best-seller" : false,
    "new" : false,
  };
  filterByOptions = [
    { 
      title: "Best Seller",
      value: "best-seller"
    },
    { 
      title: "New",
      value: "new"
    }
  ];
  public shopBannerConfig: SwiperConfigInterface = {};
  BannerSlider = [];

  isProductsFetched: boolean = false;
  images: string[] = [
    '../../../../../assets/images/BluetoothHeaderImage.jpg',
    '../../../../../assets/images/WiredHeaderImage.jpg'
  ];
  imagesMobile:string[] = [
    '../../../../../assets/images/BluetoothMobile.jpg',
    '../../../../../assets/images/WiredMobile.jpg'
  ];
  RedirectStarterID:any;
  RedirectBuilderID: any;
  RedirectHeadsetID:any;
  currentImage: string;
  @ViewChildren(MatCheckbox, { read: MatCheckbox }) matCheckboxes: QueryList<MatCheckbox>;


  /** -----------------------------
   * BuilderConfigurations
   ----------------------------- */

  BuilderConfigurations = {
    noBuilderPageForUrl : false,
    options: {
      // cacheSeconds: 0,
      // cache: true,
      data: {
        locale: "en-US",
      },
    },
    data: {
      ProductWrapperList: []
    },
    context: {
      addToCart: (item) => this.cart1Service.addToCart(item, true, "", false, false, false, false, false),
      navigate: (itemId) => this.router.navigate(["/product", itemId])
    }
  };

  productFilters = {
    searchText: "",
    selectedCategory: "all"
  };

 
  /** ------------------------------------ */


  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private productService: ProductService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    public utilityService: UtilityService,
    public itemsListService: ItemsListService,
    public companyService: CompanyService,
    public cart1Service: Cart1Service,
    public router: Router,
    private loader: LoaderService,
    private sidenavBarService: SideNavBarService,
    public user: UserService,
    public accountService: AccountService,
    private dialog: MatDialog,
    // private productSearchPipe: ProductSearchPipe,
    // private productFilterByPipe: ProductFilterByPipe,
    // private productOrderBy: ProductOrderByPipe
  ) 
  
  {

    this.productService.updateProductDetailPricing.subscribe((res) => {
      if(res) {
        if(window.location.pathname.includes('products/all')) {

          this.fetchProductsAndInitialize();

        }
      }
    });

    this.rotateImages();
    this.env = environment;
    this.userService = user.userServiceModal;
    // new functionality
    this.configService
      .getCommonSetting(
        sessionStorage.getItem("selectedCountry"),
        sessionStorage.getItem("SelectedLanguage"),
        false
      )
      .then(() => {

        if(configService.isSettingClientRegionsDone) {
          this.fetchProductsAndInitialize();
        } else {
          this.configService.ClientRegionsSetSuccessfully.subscribe((res) => {
            if (res) { 
              this.fetchProductsAndInitialize();
            }
          });
        }

      });

    // somtimes common setting loaded in time then items are called otherwise no items called

    this.sortByOrder = this.utilityService.getSortKey()[6];
    this.translate.get("global_Company_Title").subscribe((text: string) => {
      this.titleService.setTitle(
        this.translate.instant("pagetitle_product") + " | " + text,
      );
    });

    this.loader.isLoading.subscribe((v) => {
      this.showIsLoading = v;
      if (v == false) {
        this.showNoProductsError = true;
      }
    });
  }


  rotateImages() {

    return;

    let index = 0;
    this.RedirectStarterID = 779;
    this.RedirectBuilderID = 780;
    this.RedirectHeadsetID = 129;
    if(window.innerWidth < 599){
      setInterval(() => {
        this.currentImage = this.imagesMobile[index];
        index = (index + 1) % this.images.length;
        if(index == 1){
          this.RedirectStarterID = 779;
          this.RedirectBuilderID = 780;
          this.RedirectHeadsetID = 129;
          document.getElementById('headset') && ( document.getElementById('headset').style.display = 'block' );
        }
        else{
          this.RedirectStarterID = 777;
          this.RedirectBuilderID = 778;
          document.getElementById('headset') && ( document.getElementById('headset').style.display = 'none' );
        }
      }, 5000);
    }
    else{
      setInterval(() => {
        this.currentImage = this.images[index];
        index = (index + 1) % this.images.length;
        if(index == 1){
          this.RedirectStarterID = 779;
          this.RedirectBuilderID = 780;
          this.RedirectHeadsetID = 129;
          document.getElementById('headset') && ( document.getElementById('headset').style.display = 'block' );
        }
        else{
          this.RedirectStarterID = 777;
          this.RedirectBuilderID = 778;
          document.getElementById('headset') && ( document.getElementById('headset').style.display = 'none' );
        }
      }, 5000);
    }
  }

  fetchProductsAndInitialize() {
    this.RegionIDForRequest = this.companyService.getRegionID(
      sessionStorage.getItem("selectedCountry"),
    );
    if (localStorage.getItem("CommonSettings")) {
      this.RegionIDForRequest = this.companyService.getRegionID(
        this.configService.commonData.selectedCountry,
      );

      //update static customer type id for shopping
      // if (!this.accountService.isLoggedIn()) {
      //   this.userService.customerTypeID = 2;
      // }
      // either call function this.getAllProductItems(this.RegionIDForRequest); but in this base code case we are using routing params for getting products so below is that functionality

      this.route.params.subscribe((params: Params) => {
        this.selectedCategory = { CategoryId: params["category"] || "all" };

        this.isProductsFetched = false;

        // const priceGroup =  (this.productService.selectedAutoOrderItems.length + this.productService.selectedPacks.length) == 0 ? this.configService.commonData.CustomerTypes[0].ID : (this.userService.customerTypeID || this.configService.commonData.CustomerTypes[0].ID);

        this.productService
        .getProductByCategory({
          CategoryId: "" ,
          PriceGroup: this.userService.customerTypeID || this.configService.commonData.PurchaseCustomerType.CUSTOMER,
          StoreID: this.configService.commonData.PurchaseStore.RETAIL,
          // PriceGroup: priceGroup,
          // StoreID: this.configService.commonData.Stores[0].ID,
        })
        .subscribe(
          (products) => {

          this.productService.orders = products.map((item) => {
            item.Price =
              item.Price ||
              (item.Prices && item.Prices[0] && item.Prices[0].Price);
            return item;
          });

          const uniqueItems = _.uniqBy(products, (x) => x.CategoryId);
          let uniqueRequireProduct = [];
          if (
            this.configService.localSettings.Global.CategoriesToFetch
              ?.length > 0
          ) {
            uniqueItems.filter((x) => {
              if (
                this.configService.localSettings.Global.CategoriesToFetch.indexOf(
                  x.Category,
                ) > -1
              ) {
                uniqueRequireProduct.push(x);
              }
            });
          } else {
            uniqueRequireProduct = [...uniqueItems];
          }

          this.itemsListService.selectedCategories = {};
          if (this.selectedCategory.CategoryId == "all") {
            this.itemsListService.selectedCategories["all"] = true;
          }
          this.itemsListService.categoryList = _.map(
            uniqueRequireProduct,
            (object) => {
              return _.pick(object, ["CategoryId", "Category"]);
            },
          );

          this.products = [];
          const product = (this.itemsListService.products =
            this.productService.orders);
          if (
            this.configService.localSettings.Global.CategoriesToFetch
              ?.length > 0
          ) {
            product.filter((x) => {
              if (
                this.configService.localSettings.Global.CategoriesToFetch.indexOf(
                  x.Category,
                ) > -1
              ) {
                this.products.push(x);
              }
            });
          } else {
            this.products = [...product];
          }
          this.itemsListService.products = this.products;
          this.itemsListService.type = "order";
          let category;
          this.itemsListService.categoryList.filter((x) => {
            if (x.CategoryId == this.selectedCategory.CategoryId) {
              category = x.Category;
            }
          });
          if (this.selectedCategory.CategoryId == "all") {
            this.itemsListService.getItemsByCategory("all");
          } else {
            this.itemsListService.getItemsByCategory(category);
          }
          }, () => {}
          , () => {
            this.highLightSelectedCategory(params["category"]);
            this.isProductsFetched = true;

            // Send Products to builder.io
            const MappedProductsByCategory: any = this.MapProductsForBuilder(this.itemsListService.productList);
            this.BuilderConfigurations.data = MappedProductsByCategory;
            this.BuilderConfigurations.data['ProductWrapperList'] = MappedProductsByCategory;

          }
        );

        this.slides = this.configService.localSettings.Product.PrimaryBanner;
      });
      this.sortKeys = this.utilityService.getSortKey();
      this.env = environment;
    } else {
      // can use else for commonsetting not found in storage
    }
  }

  MapProductsForBuilder(productsArray: any) {
    return productsArray.reduce((WrapperList, item) => {
      const isCategoryExist = WrapperList.find((group) => group.CategoryId === item.CategoryId);
  
      if (isCategoryExist) {
        isCategoryExist.items.push(item);
      } else {
        WrapperList.push({
          Category: item.Category,
          CategoryId: item.CategoryId,
          items: [item],
        });
      }
  
      return WrapperList;
    }, []);
  }

  ngOnInit() {
    this.BannerSlider = [
      {
        desktopImage: "../../../../../assets/images/banner-slides/JulyBannerDesktop.jpg",
        tabletImage: "../../../../../assets/images/banner-slides/JulyBannerMobile.jpg",
        mobileImage: "../../../../../assets/images/banner-slides/JulyBannerMobile.jpg",
        button1: { allowed: false, id: 0, text: 'STARTER'},
        button2: { allowed: false, id: 0, text: 'BUILDER'},
        button3: { allowed: false, id: 0, text: 'HEADSET'}
      },
      {
        desktopImage: "../../../../../assets/images/banner-slides/JuneIncentiveBanner.jpg",
        tabletImage: "../../../../../assets/images/banner-slides/JuneIncentiveBanner.jpg",
        mobileImage: "../../../../../assets/images/banner-slides/JuneIncentiveBanner.jpg",
        button1: { allowed: false, id: 0, text: 'STARTER'},
        button2: { allowed: false, id: 0, text: 'BUILDER'},
        button3: { allowed: false, id: 0, text: 'HEADSET'}
      },
      {
        desktopImage: "../../../../../assets/images/banner-slides/BluetoothImageDesktop.jpg",
        tabletImage: "../../../../../assets/images/banner-slides/Bundle1Image.jpg",
        mobileImage: "../../../../../assets/images/banner-slides/BluetoothImagePhone.jpg",
        button1: { allowed: true, id: 779, text: 'STARTER'},
        button2: { allowed: true, id: 780, text: 'BUILDER'},
        button3: { allowed: true, id: 129, text: 'HEADSET'}
      },
      {
        desktopImage: "../../../../../assets/images/banner-slides/SupplementBundleDesktop.jpg",
        tabletImage: "../../../../../assets/images/banner-slides/Bundle2Image.jpg",
        mobileImage: "../../../../../assets/images/banner-slides/SupplementBundlePhone.jpg",
        button1: { allowed: true, id: 1201, text: 'BALANCE'},
        button2: { allowed: true, id: 1202, text: 'RADIANCE'},
        button3: { allowed: true, id: 1203, text: 'VITALITY'}
      },
      {
        desktopImage: "../../../../../assets/images/banner-slides/WiredImageDesktop.jpg",
        tabletImage: "../../../../../assets/images/banner-slides/Bundle3Image.jpg",
        mobileImage: "../../../../../assets/images/banner-slides/WiredImagePhone.jpg",
        button1: { allowed: true, id: 777, text: 'STARTER'},
        button2: { allowed: true, id: 778, text: 'BUILDER'},
        button3: { allowed: false, id: 0, text: ''}
      }
    ];

    this.shopBannerConfig = {
      autoplay: false,
      speed: 1000,
      observer: true,
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: true,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 1,
        },
        960: {
          slidesPerView: 1,
        },
        1280: {
          slidesPerView: 1,
        },
      },
    };

    this.route.queryParams.subscribe((params) => {
      const { searchtext } = params;
      if(searchtext) {
        this.productSearchText = searchtext?.toString();
      }
    });

    this.sortByOrder = this.utilityService.getSortKey()[0];
    this.translate.get("global_Company_Title").subscribe((text: string) => {
      this.titleService.setTitle(
        this.translate.instant("pagetitle_product") + " | " + text,
      );
    });
  }



  compareObjects(o1,o2): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  onChangeSorting($event) {
    this.sortByOrder = $event.value;
  }

  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }

  highLightSelectedCategory(id) {
    if(id != "all") {
      this.productSearchText = "";
      for(const category in this.itemsListService.selectedCategories) {
        if(category == id) {
          this.itemsListService.selectedCategories[category] = true;
        } else {
          this.itemsListService.selectedCategories[category] = false;
        }
      }
    }
    else if(id === "all"){
      for(const category in this.itemsListService.selectedCategories) {
        this.itemsListService.selectedCategories[category] = true;
      }
    }
  }

  onChange(selectedCategories, id) {

    /**---------------------------------------------------------- */
    
    this.highLightSelectedCategory(id);
    
    /**---------------------------------------------------------- */


    let result = true;
    for (const i in selectedCategories) {
      if (selectedCategories[i] === false) {
        if (i !== "all") {
          result = false;
          break;
        }
      } else {
        result = true;
      }
    }

    if (result) {
      this.itemsListService.selectedCategories["all"] = true;
    } else {
      this.isAllunselect = true;
      for (const k in selectedCategories) {
        if (selectedCategories[k] === true) {
          this.isAllunselect = false;
          break;
        }
      }
      if (this.isAllunselect) {
        this.itemsListService.selectedCategories["all"] = false;
        this.itemsListService.selectedCategories[id] = true;
      } else {
        this.itemsListService.selectedCategories["all"] = false;
      }
    }
    if (!this.isAllunselect) {
      this.itemsListService.getItemsByCategory(selectedCategories);
    }
  }

  mouseEnter(index) {
    return;
    if (screen.width > 960) {
      document.getElementById("btn" + index).classList.add("faded-in");
      document.getElementById("btn" + index).classList.remove("faded-out");
      setTimeout(() => {
        document.getElementById("btn" + index).style.opacity = "1";
      }, 590);
    }
  }
  mouseOut(index) {
    return;
    if (screen.width > 960) {
      document.getElementById("btn" + index).classList.add("faded-out");
      document.getElementById("btn" + index).classList.remove("faded-in");
    }
  }

  handleProduct(item) {
    if (item.ItemOptions.length > 0) {
      this.router.navigate(["/product", item.ItemID]);
    } else {
      this.OrderQuantityCount = item && item.Quantity ? item.Quantity : 1;
      if (this.cart1Service["orderQuantity"][item.ItemID] >= 1) {
        this.increaseQuantiy("order", item);
      } else {
        this.cart1Service["orderQuantity"][item.ItemID] =
          this.OrderQuantityCount;
        this.cart1Service.addToCart(
          item,
          true,
          item.ItemID,
          false,
          false,
          false,
          true,
        );
      }
    }
  }
  increaseQuantiy(type, item) {
    this.cart1Service.increaseQuantiy(item, type == "autoship", type == "pack");
  }

  goToShop(id) {
    this.router.navigate(["/product", id]);
  }

  addToCart(product) {

    if(product.ItemOptions.length > 0) {
      this.dialog.open(VariantSelectionComponent, {
        data: {item : product},
        width: '400px',
      });
      return;
    }

    if(product?.ItemID == 'e97') {
      this.addToBothCart(product);
      return;
    }

    this.cart1Service.addToCart(
      product,
      true,
      product.ItemID,
      false,
      false,
      false,
      false,
    );

    this.sidenavBarService.showsideBarSummary = true;
  }

  addToBothCart(product) {
    // Autoship
    this.cart1Service.addToCart(product, true, '', true, true, false, false);

    // Order
    this.cart1Service.addToCart(product, true, '', false, true, false, false);
  }

  checkBoxParentClicked($event:any, checkboxId: string | number) {
    $event.preventDefault();
    this.matCheckboxes.forEach(checkbox => {
      if(checkbox._elementRef.nativeElement.getAttribute('checkbox-id')  ==   checkboxId  )
        checkbox.checked = true;
        this.onChange(this.itemsListService.selectedCategories, checkboxId);
        return;
      });
    // const checkbox: any = document.querySelector(`[checkbox-id="${checkboxId}"]`);
    // if(checkbox) {
    //   checkbox.checked = true;
    // }
  }

  handleProductSearch() {

    let Products = [];

    if(this.BuilderConfigurations.data.ProductWrapperList.length === 0) {
      Products = this.itemsListService.productList;
    }

    if(this.productFilters.selectedCategory === "all") {
      Products = this.itemsListService.productList;
    } else {
      Products = this.itemsListService.productList;
      Products = Products.filter((product) => product.CategoryId == this.productFilters.selectedCategory);
    }

    //  Products of the selected category
    if(Products?.length > 0) {

      const productSearchPipe = new ProductSearchPipe();
      const items = productSearchPipe.transform(Products, this.productFilters.searchText);

      // Send Products to builder.io
      const MappedProductsByCategory: any = this.MapProductsForBuilder(items);
      this.BuilderConfigurations.data = MappedProductsByCategory;
      this.BuilderConfigurations.data['ProductWrapperList'] = MappedProductsByCategory;

    }

    if(this.productFilters.searchText === "") {
      this.productFilters.selectedCategory = "all";
      // Send Products to builder.io
      const MappedProductsByCategory: any = this.MapProductsForBuilder(this.itemsListService.productList);
      this.BuilderConfigurations.data = MappedProductsByCategory;
      this.BuilderConfigurations.data['ProductWrapperList'] = MappedProductsByCategory;
    }

  }
}

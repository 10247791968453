<div fxLayout="column" id="prod-details">
   <div class="color-b">
      <div class="container main">
         <div class="details-wrapper p-20">
            <div fxLayout="column" fxFlex="100" *ngIf="product.ItemID">
               <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100" fxLayoutGap.gt-sm="80px">
                  <div fxFlex.gt-sm="50" fxLayout="column">

                     <!-- (click)="goToslider(product.ItemID)" -->
                     <div *ngIf="product.Images.length == 0" class="prodBox cursor-pointer" fxFlex="100" >
                        <img [src]="env.imageUrl +product.ImageUrl" alt="" class="img-bg img-fluid">
                     </div>

                     <div *ngIf="product.Images.length > 0" class="swiper-container m-b-20 gallery-top" [swiper]="GalleryTopImages">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide cursor-pointer" *ngFor="let image of product.AllImagesUrls">
                              <img [src]="image.url" alt="" class="img-bg img-fluid">
                           </div>
                        </div>
                     </div>

                     <!-- Image Slider ( Multiple Images ) -->

                     <div *ngIf="product.Images.length > 0" class="swiper-container gallery-thumbs" [swiper]="GalleryThumbImages">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide" *ngFor="let image of product.AllImagesUrls">
                              <img [src]="image.url" alt="" class="img-bg img-fluid">
                           </div>
                        </div>
                     </div>

                  

                  </div>
                  <div fxFlex.gt-sm="50" fxFlex.gt-md="40" fxLayout="column"
                     fxLayoutAlign="start start" class="m-b-30">
                     <div class="product-right w-100 m-t-30 prod-details-right-section">
                        <div>
                           <span class="product-title" [textContent]="product?.ProductName"></span>
                           <div *ngIf="product?.Custom?.Field5" class="product-subheader" [textContent]="product?.Custom?.Field5"></div>
                           <!-- <p class="price f-s-14 f-w-400"
                              *ngIf="!productsService?.catalogMode && product && product.Prices">
                              {{product?.Prices[0] && product?.Prices[0].Price |
                              currency:companyService.selectedCurrency.CurrencyCode}}</p> -->
                        </div>

                        <hr style="margin:28px 0px; border-color:var(--theme-default-blackish)">

                        <!-- Will modify from javascript -->
                        <div class="Backoffice_HTML_Block">
                           <div class="short-description">
                              <!-- <div [innerHTML]="product.Description"> </div> -->
                           </div>
                        </div>

                        <hr style="margin:28px 0px; border-color:var(--theme-default-blackish)" *ngIf="product?.Description">

                        <div class="price-column m-b-20">
                           <div class="price f-w-400 f-s-36 c-black"
                              [textContent]="getPrice(product, OrderQuantityCount) | currency:companyService.selectedCurrency.CurrencyCode">
                           </div>
                           <span *ngIf="itemPurchaseSelectionType == 'type-subscribe'" class="tag-line color-blackish text-italic">Why Subscribe? Better prices, set it and forget it.</span>
                           <!-- <span *ngIf="product?.AllowAutoship && (product?.Price - product?.AutoshipPrice > 0)"
                              translate
                              [translateParams]="{value:(product.Price - product.AutoshipPrice | currency:companyService.selectedCurrency.CurrencyCode)}">subscribe_autoship</span> -->
                        </div>

                        <div fxLayout="column" fxLayoutGap="30px">
                           <!-- Radio Buttons -->
                           <div *ngIf="product.ItemID != 'e97'" class="purchase-SelectionType">
                              <mat-radio-group fxLayout="column" fxLayoutGap="18px" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="25px" name="purchase" [(ngModel)]="itemPurchaseSelectionType">
                                 <mat-radio-button value="type-subscribe" *ngIf="product.AllowAutoship">Subscribe & Save</mat-radio-button>
                                 <mat-radio-button value="type-one-time">One-time Purchase</mat-radio-button>
                               </mat-radio-group>
                           </div>

                           <!-- Quantity and ATC -->
                           <div class="purchase-Quantity_Button_Box m-b-20" fxLayout="column" fxLayoutGap="18px" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="25px">
                              <mat-form-field appereance="none"
                              [ngClass]="{ 'fade-input-area' : product.ItemID == 'e97' } "
                              >
                                 <mat-select [(ngModel)]="OrderQuantityCount" placeholder="Quantity">
                                    <mat-option [value]="i + 1" *ngFor="let quantity of QuantityArray; let i = index">
                                       {{i + 1}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                              <button class="atc-btn" (click)="addToCart('check-type', product)">
                                 <!-- <mat-icon class="m-r-15">shopping_basket</mat-icon> -->
                                 <div> Add To Cart </div>
                              </button>
                           </div>

                           <!-- Share button -->
                           <div class="purchase-Quantity_Button_Box m-b-20" fxLayout="column" fxLayoutGap="18px" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="25px">
                              <button class="share-btn" (click)="copyProductLink()">
                                 <div> Share </div>
                              </button>
                           </div>


                           <!-- Buy with Installments -->
                           <div *ngIf="isInstallmentAllowed(product)" class="buy-with-installment">
                              <div class="btn" fxLayoutGap="16px" fxLayoutAlign="center center" (click)="addToCart('check-type', product, 'installment')">
                                 <span>Buy with Installments</span>
                              </div>
                              <div style="max-width: 25rem;" *ngIf="getInstallmentsNumber(product) > 0 && todaysInstallmentAmount(product, OrderQuantityCount) > 0" class="m-t-10 color-blackish">Your {{ getInstallmentsNumber(product) }} installment payments will be <strong>{{ todaysInstallmentAmount(product, OrderQuantityCount) | currency:companyService.selectedCurrency.CurrencyCode }}</strong> beginning with your first payment today. </div>
                           </div>

                        </div>


                        <!-- <div class="product_detail_qv">
                        </div> -->
                        
                        <!-- <div class="price-column">
                           <p> {{'global_pv'|translate}}<span> :
                                 {{OrderQuantityCount * (product?.CV || product?.QV || 0)}}</span></p>
                        </div> -->
                        <div class="layout-row" fxLayout="row" *ngIf="product?.HasOptions">
                           <div class="w-100 checkoptionbox">

                              <ng-container *ngFor="let option of product?.ItemOptions; let i = index">
                                 <div class="m-0 p-20-0 custom-size checkoptionbox">
                                    <p [textContent]="option.Option" class="f-w-700 text"></p>
                                    <mat-form-field appearance="outline">
                                       <mat-select readonly="true" [(ngModel)]="value[i]"
                                          (selectionChange)="checkOptions(product?.ItemOptions);" name="value.Option"
                                          placeholder="Select {{option.Option}}" (click)="openSelect($event)"
                                          class="md-no-underline-select">
                                          <mat-option
                                             *ngFor="let value of getValidValues(product, option, option.Values)"
                                             (ngInit)="setInitialItemOption(option, value)" [value]="value.Option"
                                             (click)="value.isAvailable && selectOption(product, option, value, false)">
                                             {{value.Option}} </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
                              </ng-container>
                           </div>
                        </div>


                        <!-- <div class="quantity">
                           <span class="f-w-700">Quantity</span>
                           <div fxLayout="row" fxLayout.xs="column" class="text-muted">
                              <div fxLayout="row" fxLayoutAlign="center start">
                                 <button
                                    [disabled]="(!isOptionsSelected && product?.HasOptions && product?.ItemOptions?.length>0)"
                                    mat-icon-button matTooltip="Remove" (click)="decreaseQuantity()">
                                    <mat-icon>remove</mat-icon>
                                 </button>
                                 <input [(ngModel)]="OrderQuantityCount" maxlength="2" validate
                                    [disabled]="!isOptionsSelected && product?.HasOptions && product.ItemOptions && product.ItemOptions?.length>0"
                                    inputonlynumber="true" name="quantity"
                                    (keyup)="($event.which === 38) ? increaseQuantity() : 0"
                                    (keydown)="($event.which === 40) ? decreaseQuantity() : 0"
                                    class="form-control input-number">
                                 <button
                                    [disabled]="(!isOptionsSelected && product?.HasOptions && product.ItemOptions && product?.ItemOptions.length>0)"
                                    mat-icon-button matTooltip="Add" (click)="increaseQuantity()">
                                    <mat-icon>add</mat-icon>
                                 </button>
                              </div>
                           </div>
                        </div> -->


                        <!-- <div class="buttons mt-20">
                           <button
                              *ngIf="!hideAddToCart(product) && !(persistentService.retailData.CurrentSelectedAutoOrder > 0 && utilityService.getAutoshipEditFlag())"
                              [disabled]="product?.HasOptions && !isOptionsSelected && product?.ItemOptions?.length>0"
                              mat-raised-button color="primary" class="btn-project w-100"
                              (click)="addToCart('order', product)" translate="">{{'global_addtocart' |
                              translate}}</button>
                           <button *ngIf="hideAddToCart(product, true)"
                              [disabled]="product?.HasOptions && !isOptionsSelected && product?.ItemOptions?.length>0"
                              mat-raised-button color="primary" class="btn-project w-100 m-t-10"
                              (click)="addToCart('autoship', product)" translate="">{{'autoship_' | translate}}</button>
                        </div> -->
                     </div>

                     <!-- <hr class="w-100" style="margin: 28px 0 0px;border-color:var(--theme-default-blackish);width: 100%;"> -->

                     <div class="prodReview product-right w-100">
                        <mat-tab-group *ngIf="ProductSpecification.length > 0">
                           <mat-tab *ngFor="let tabs of ProductSpecification" [label]="tabs?.title" class="font-IBM f-w-500">
                              <div class="Backoffice_HTML_Block">
                                 <p class="font-IBM review-wrapper p-t-15" fxFlex="100" fxFlex.md="75" [innerHtml]="tabs?.content | unsafe"></p>
                              </div>
                           </mat-tab>
                         </mat-tab-group>

                        <div *ngIf="ProductSpecification.length == 0 && product?.Specifications" class="Backoffice_HTML_Block">
                           <p class="font-IBM review-wrapper p-t-15" fxFlex="100" fxFlex.md="75" [innerHtml]="product?.Specifications | unsafe"></p>
                        </div>
                        
                        <!-- <mat-accordion>
                           <mat-expansion-panel class="font-IBM" [expanded]="true">
                              <mat-expansion-panel-header class="arrow-color">
                                 <mat-panel-title>
                                    <span class="f-s-20 f-w-700 question font-IBM" translate="product_details"></span>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="font-IBM" class="review-wrapper" fxFlex="100" fxFlex.md="75"
                                 [innerHtml]="product?.Description | unsafe"></p>
                                 
                           </mat-expansion-panel>
                           <mat-expansion-panel class="font-IBM" *ngIf="product.AllowAutoship">
                              <mat-expansion-panel-header class="arrow-color">
                                 <mat-panel-title>
                                    <span class="f-s-20 f-w-700 question font-IBM" translate="autoship_"></span>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="font-IBM" class="review-wrapper" fxFlex="100" fxFlex.md="75"
                                 [innerHtml]="product?.Specifications | unsafe"></p>
                           </mat-expansion-panel>
                        </mat-accordion> -->
                     </div>
                  </div>
               </div>
               <!-- perfect stretch -->
               <!-- <div class="m-t-20">
                  <div fxLayout="column">
                     <div fxLayout="column" fxLayoutAlign="center" fxFlex="50" class="bg-img p-relative">
                        <img
                           src="https://cdn.shopify.com/s/files/1/0926/9298/files/AV200723_SH05_059.jpg?v=1600978896&width=1512"
                           alt="image">
                        <div class="content p-abs c-white">
                           <div class="p-relative p-content-div">
                              <p><span class="f-s-12">Our Gaurentee</span></p>
                              <p class="m-t-12 f-s-36 f-w-700">The Perfect Stretch</p>
                              <p class="m-t-20 w-35">Each {{'Clientname' | translate}} pieces comes tested in 360 degree
                                 flexibility tests. That's how we gaurentee the perfect stretch.</p>
                              <p class="m-t-30 shop-btn" routerLink="/products/all"><a class="c-black f-s-14">Shop
                                    now</a></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <hr style="margin:28px 0px; border-color:var(--theme-default-grey)"> -->
               <!--Recommended-->
               <!-- <div class="p-30">
                  <div fxLayout="column">
                     <p class="f-s-24 f-w-700">Recommended</p>
                     <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row"
                        fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" fxFlex="100">
                        <div *ngFor="let productImage of healthBeautyProduct; let i=index" fxLayout="column" fxFlex="32"
                           fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
                           <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i)"
                              (mouseout)="mouseOut(i)" fxLayout="column" fxFill class="p-relative cursor-pointer"
                              id="parent">
                              <img (click)="goToShop(productImage.ItemID)" [src]="env.imageUrl +productImage.ImageUrl"
                                 alt="" class="img-bg2" [id]="'product'+i" style="width: 100% !important;">
                              <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                                 <div class="p-absolute abs-width">
                                    <button [id]="'btn'+i"
                                       class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                                       mat-raised-button color="black" (click)="addProduct(productImage)">Quick
                                       Add</button>
                                 </div>
                              </div>
                           </div>
                           <div fxFlex="10" fxLayout="row" class="content m-t-20">
                              <div fxFlex="70">
                                 <p><span class="font-IBM">{{productImage.ProductName}}</span></p>
                                 <p><span class="m-t-10 font-IBM"><del
                                          *ngIf="productImage.oldPrice">{{productImage.oldPrice}} </del>
                                       {{'$'+productImage.Price}}</span></p>
                              </div>
                              <div *ngIf="productImage.oldPrice" fxFlex="30" class="text-end m-t-10">
                                 <p class="f-s-12 sale" color="white">Sale</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
      <div class="suggestedProductBox">
         <p class="suggestedText">Suggested Products</p>
      </div>

      <div class="container main p-30">
         <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px">
            <div *ngFor="let relProduct of relatedProducts" class="" fxFlex.gt-sm="33" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
               <img [routerLink]="['/product', relProduct.ItemID]" [src]="configService.localSettings?.Disco?.DiscoUrl + '/' + relProduct.ImageUrl" alt="" class="w-20 cursor-pointer">
               <span class="c-black" [textContent]="relProduct?.ProductName"></span>
               <div class="price f-w-600 f-s-16 c-black"
                  [textContent]="getPrice(relProduct, OrderQuantityCount) | currency:companyService.selectedCurrency.CurrencyCode">
               </div>
               <div class="purchase-Quantity_Button_Box m-t-15" fxLayout="row" fxLayoutGap="18px" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="25px">
                  <button class="suggested-btn">
                     <div (click)="AddSuggestedProductToCart(relProduct)"> Add </div>
                  </button>
                  <button class="suggested-btn">
                     <div [routerLink]="['/product', relProduct.ItemID]"> Details </div>
                  </button>
               </div>
            </div>
         </div>
      </div>

      <div class="container main m-t-20">
         <div class="text-center f-w-300 f-s-24 c-black statements">*These statements have not been evaluated by the Food and Drug Administration.<br>
            This Product is not intended to diagnose, treat, cure, or prevent any disease. </div>
      </div>
      
      <div style="margin:28px 0px;"></div>
      <!-- <hr style="margin:28px 0px; border-color:var(--theme-default-grey)"> -->
      <!-- <div class="products sec-padding p-top-0">
         <div>
            <div fxLayout="column">
               <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row"
                  fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" fxFlex>
                  <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
                     <div class="img-hover text-center">
                        <p class="f-s-18 f-w-700 m-t-10">Free shipping</p>
                        <p class="f-s-14 f-w-400 m-t-10">All orders over $100 come with complimentary free
                           shipping. Simply check out normally.</p>
                     </div>
                  </div>
                  <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
                     <div class="img-hover text-center">
                        <p class="f-s-18 f-w-700 m-t-10">Effortless Returns</p>
                        <p class="f-s-14 f-w-400 m-t-10">Each package comes with a prepaid return label. You've
                           got sixty days to send it back.</p>
                     </div>
                  </div>
                  <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
                     <div class="img-hover text-center">
                        <p class="f-s-18 f-w-700 m-t-10">Gift Cards</p>
                        <p class="f-s-14 f-w-400 m-t-10">Not sure what to get as a gift? Grab a gift card and
                           use it at any one of our retail locations.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
   </div>
</div>
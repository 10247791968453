import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../shared/services/account.service';
import { AutoshipConfigurationService } from '../../shared/services/autoshipConfiguration.service';
import { Cart1Service } from '../../shared/services/cart1.service';
import { CompanyService } from '../../shared/services/company.service';
import { ConfigService } from '../../shared/services/config.service';
import { ItemsListService } from '../../shared/services/itemsList.service';
import { NotificationService } from '../../shared/services/notification.service';
import { OrderService } from '../../shared/services/order.service';
import { PaymentService } from '../../shared/services/payment.service';
import { PersistentService } from '../../shared/services/persistent.service';
import { ProductService } from '../../shared/services/product.service';
import { RegexService } from '../../shared/services/regex.service';
import { RestApiService } from '../../shared/services/restapi.service';
import { ShoppingCartService } from '../../shared/services/shopping-cart.service';
import { UserService } from '../../shared/services/user.service';
import { UtilityService } from '../../shared/services/utility.service';
import { ValidateKeywordService } from '../../shared/services/validatekeyword.service';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { HostedpaymentDialogComponent } from '../../shared/model/hostedpayment-dialog/hostedpayment-dialog.component';
import { getSubdomain } from 'tldts';

@Component({
  selector: 'app-starter-pack-checkout',
  templateUrl: './starter-pack-checkout.component.html',
  styleUrls: ['./starter-pack-checkout.component.scss']
})
export class StarterPackCheckoutComponent implements OnInit {
  checkoutForm: FormGroup;
  loginForm: FormGroup;
  userService: UserServiceModal;
  allowedCountries = [];
  states = [];
  region = [];
  storeId: any;
  category: any;
  itemData: any;
  ItemId: any;
  MerchantData: any = [];
  customerTotalOrder: any;
  merchantId: any;
  productname: any;
  showPassword = false;
  showloginPassword = false;
  showConfirmPassword = false;
  loginDiv = false;
  CommonData: any;
  previousType: any;
  productSubTotal: any
  productShippingTotal: any;
  productTaxTotal: any;
  productTotal: any;
  passwordMismatch = false;
  constructor(
    public utilityService: UtilityService,
    private titleService: Title,
    public configService: ConfigService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public itemsService: ProductService,
    public apiService: RestApiService,
    public user: UserService,
    public notificationService: NotificationService,
    public paymentService: PaymentService,
    public translate: TranslateService,
    public cart1Service: Cart1Service,
    public orderService: OrderService,
    public validateKeyword: ValidateKeywordService,
    public itemsListService: ItemsListService,
    public regexService: RegexService,
    public router: Router,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public companyService: CompanyService,
    public accountService: AccountService,
    public shoppingCartService: ShoppingCartService,
    public persistentService: PersistentService,
    private fb: FormBuilder) {
    this.userService = this.user.userServiceModal
    this.formInitialization()


    this.CommonData = JSON.parse(localStorage.getItem("CommonSettings"))
    console.log("this.CommonData", this.CommonData)
    this.allowedCountries = JSON.parse(
      sessionStorage.getItem("allowedCountries"),
    );
  }

  ngOnInit(): void {
    this.previousType = this.userService.customerTypeID;

    this.route.queryParams.subscribe((params: any) => {
      this.ItemId = params.itemid
    });
    this.getStates();
    this.getregion();
    this.getstoreId();
    this.checkoutForm.get('password')?.valueChanges.subscribe(() => this.checkpassword());
    this.checkoutForm.get('confirmPassword')?.valueChanges.subscribe(() => this.checkpassword());

  }
  formInitialization() {
    this.checkoutForm = this.fb.group({
      customerType: ['1', Validators.required],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^([\\w-\\.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\\]?)$")]],
      country: ['47125C2CDE', Validators.required],
      streetAddress1: ['', Validators.required],
      streetAddress2: [''],
      city: ['', Validators.required],
      state: ['UT', Validators.required],
      postcode: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(10)]],
      username: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      differentAddress: [false],
      userWebalias: ['', Validators.required],
      orderNotes: [' '],
      MerchantId: ['0'],
    },
    { validators: this.passwordMatchValidator() }
    );

    this.loginForm = this.fb.group({
      UserNameEmail: ['', [Validators.required]],
      loginPassword: ['', [Validators.required, Validators.minLength(6)]],
      rememberPass: [0]
    })
  }
  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;
  
      if (!control.get('password') || !control.get('confirmPassword')) {
        return null;
      }

      if (password !== confirmPassword) {
        control.get('confirmPassword')?.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        control.get('confirmPassword')?.setErrors(null);
        return null;
      }
    };
  }

  loginUser() {
    if(this.loginForm.invalid) {
      return;
    }else {
    localStorage.setItem("guestLogin", "false");
    this.user.clearCustomerData();
    localStorage.removeItem("guestLogin");
    const loginrequest = {
      granttype: "password",
      scope: "office",
      username: this.loginForm.value.UserNameEmail,
      password: this.loginForm.value.loginPassword,
    };
    this.apiService.Login(loginrequest).subscribe((result) => {
      if (result && result.status == 200 && result.body.Status != 1) {
        try {
          localStorage.setItem("isLoggedIn", "true");
          this.accountService
            .getCustomerData(result.body.CustomerId)
            .then((data: any) => {
              if (data && data.Data) {
                //  Clear cart
                // this.cartsService.clearCart();
                // getting userdata
                localStorage.setItem("user", JSON.stringify(data.Data));
                this.userService.customerData = data.Data;
                localStorage.setItem(
                  "userService",
                  JSON.stringify(this.userService),
                );
                this.userService.customerTypeID =
                  this.userService.customerData.CustomerType;
                if (this.previousType !== this.userService.customerTypeID) {
                  this.userService.couponInfo.Allcoupons = [];
                  // this.autoshipConfigurationService.autoshipDate = "";
                  this.userService.shippingMethods = [];
                  this.userService.selectedShippingMethod = 0;
                  // this.autoshipConfigurationService.init();
                }
                // if (
                //   !this.utilityService.isEmptyObject(
                //     this.userService.customerData.DefaultShippingAddress,
                //   )
                // ) {
                //   this.user.setShippingAddress();
                // } else {
                //   this.userService.shippingAddress =
                //     new UserServiceModal().newshippingAddress;
                // }
                if (this.userService.customerData.DefaultShippingAddress == null) {
                  this.userService.shippingAddress =
                    new UserServiceModal().newshippingAddress;
                }

                if (this.userService.customerData.WebAlias) {
                  this.apiService
                    .validateWebAlias(this.userService.customerData.WebAlias)
                    .subscribe(
                      (res: any) => {
                        if (!res.Data) {
                          // calling redirect function
                          this.userService.WebAlias =
                            this.userService.customerData.WebAlias;
                          this.loginredirect(this.userService.WebAlias);
                        }
                      },
                      (error) => {
                        if (error.Data && !error.Data.WebAlias) {
                          this.notificationService.error(
                            "error_",
                            "webalias_not_exists",
                          );
                          return false;
                        }
                      },
                      () => { },
                    );
                } else if (this.userService.customerData.SponsorId) {
                  this.apiService
                    .getSearchCustomerDetail(
                      this.userService.customerData.SponsorId,
                    )
                    .subscribe(
                      (resp: any) => {
                        if (resp.Data) {
                          this.apiService
                            .validateWebAlias(resp.Data.WebAlias)
                            .subscribe(
                              (res: any) => {
                                if (res.Data) {
                                  // calling redirect function
                                  this.userService.WebAlias =
                                    resp.Data.WebAlias;
                                  this.loginredirect(
                                    this.userService.WebAlias,
                                  );
                                }
                              },
                              (error) => {
                                if (error.Data && !error.Data) {
                                  this.notificationService.error(
                                    "error_",
                                    "webalias_not_exists",
                                  );
                                  return false;
                                }
                              },
                              () => { },
                            );
                        }
                      },
                      (error) => {
                        if (error.Data && !error.Data.WebAlias) {
                          this.notificationService.error(
                            "error_",
                            "webalias_not_exists",
                          );
                          return false;
                        }
                      },
                      () => { },
                    );
                }
              } else {
                this.notificationService.error(
                  "error_",
                  "error_occured_try_again",
                );
              }
            })
            .catch((error) => {
              this.notificationService.error(
                "error_",
                "error_occured_try_again",
              );
              console.error("ex", error);
            });
        } catch (successEx) {
          this.notificationService.error("error_", "error_occured_try_again");
          console.error("ex", successEx);
        }
      } else {
        this.notificationService.error("error_", "Authentication Failed");
      }
    })
   }
  }
  checkitem() {
    return (
      this.itemsService.selectedOrderItems.length > 0 ||
      this.itemsService.selectedAutoOrderItems.length > 0
    );
  }
  private loginredirect(alias) {
    // let newUrl: string;
    // if (this.checkoutpage) {
    //   newUrl = `${location.origin}/${alias}/checkout`;
    // } else {
    //   newUrl = `${location.origin}/${alias}`;
    // }
    let newUrl: any;
    const subdomain = getSubdomain(window.location.host);
    newUrl = `${location.origin}/${alias}`;
    if (this.checkitem()) {
      this.userService.checkItems = true;
      window.location.assign(newUrl.replace("login", "/checkout"));
    }
    //  else if (this.itemsService.selectedAutoOrderItems.length) {
    //   this.userService.checkItems = true;
    //   window.location.assign(newUrl.replace("login", "products/all"));
    // } 
    else {
      window.location.assign(newUrl + '/products/all');
    }
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  toggleloginPasswordVisibility(): void {
    this.showloginPassword = !this.showloginPassword;
  }
  showlogint(): void {
    this.loginDiv = !this.loginDiv;
  }
  verifyUserNameAndEmail(email: string) {
    if (email) {
      this.checkoutForm.value.email = email.replace(/\s/g, "");
      const filter = new RegExp(
        "^([\\w-\\.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\\]?)$",
      );
      if (this.checkoutForm.value.email && filter.test(this.checkoutForm.value.email)) {
        this.apiService
          .validateEmailAddress({ EmailAddress: email })
          .subscribe((result) => {
            if (!result.Data) {
              this.notificationService.error("error_", "error_email_exists");
              this.checkoutForm.value.email = "";
              this.checkoutForm.controls['email'].reset();
              this.checkoutForm.value.username = "";
              this.checkoutForm.controls['username'].reset();
            } else {
              this.notificationService.success("success", "email_available_");
              this.userService.personalInfo.Email = email;
              this.checkoutForm.controls['username'].setValue(this.checkoutForm.value.email);

              this.checkoutForm.value.username = this.checkoutForm.value.email
            }
          });
      }
    }else {
      this.checkoutForm.value.username = "";
      this.checkoutForm.controls['username'].reset();
    }
  }
  getstoreId() {
    this.apiService.getStores().subscribe((result) => {
      this.storeId = result.Data[1].ID
      this.getcategory(this.storeId)
    })
  }
  getcategory(data: any) {
    this.apiService.GetCategories(data).subscribe((result) => {
      this.category = result[0].ID;
      this.getItembyFilter(this.checkoutForm.value.customerType)
    })
  }
  getItembyFilter(data: any) {

    const request = {
      CurrencyCode:
        this.companyService.selectedCurrency?.CurrencyCode ||
        "USD",
      LanguageCode:
        this.configService.commonData.selectedLanguage ||
        "en",
      RegionID: this.region[0].ID,
      PriceGroup: data,
      StoreID: this.storeId,
      CategoryId: this.category,
    };
    this.apiService.getItemsbyFilter(request).subscribe(data => {
      this.itemData = data.Data
      localStorage.setItem("ItemData", data.Data)
      this.calculateCustomerTotalOrder()
      // if(this.checkoutForm.value.customerType){
      //   this.calculateCustomerTotalOrder(this.checkoutForm.value.customerType)
      // }
    })
  }
  verifyUserName(username: any) {
    if (username) {
      this.apiService.validateUsername(username).subscribe((result) => {
        if (result.Data == true) {
          this.notificationService.success(
            "success",
            "UserName available"
          );
        } else {
          this.checkoutForm.controls['username'].reset()
          this.checkoutForm.value.username = ""
          this.notificationService.error(
            "error_",
            "UserName not available"
          );
        }
      });
    }
  }
  getStates(country = "US") {
    // if(this.CommonData.)
    this.apiService.getStates(country)
      .subscribe(({ Data }) => {
        this.states = Data;
      });
  }
  getregion() {
    if (this.CommonData.Regions.length > 0) {
      this.region = this.CommonData.Regions;
    } else {
      this.apiService.getRegions()
        .subscribe(({ Data }) => {
          this.region = Data;
        });
    }
  }
  checkZipValidate() {
    if (!this.checkoutForm.value.postcode) {
      return;
    }
    else {
      this.calculateCustomerTotalOrder()
    }
  }

  calculateCustomerTotalOrder() {
    const productdetails = [];
    const kititems = [];

    productdetails.push({
      ItemId: this.ItemId,
      Quantity: this.itemData[0].Quantity || 1,
      IsReward: false, // item.UsePoints
    });
    kititems.push({
      ItemId: this.itemData[0].ItemID,
      Quantity: this.itemData[0].Quantity || 1,
      IsReward: false, // item.UsePoints
    });
    const CustomerRequest = {
      customerType: this.checkoutForm.value.customerType,
      customerId: "",
      OrderId: "",
      calculateOrder: {
        // WarehouseId: 0,
        ShipMethodId: this.userService.selectedShippingMethod,
        StoreId: this.storeId,
        CurrencyCode: this.companyService.selectedCurrency.CurrencyCode,
        ShippingAddress: {
          AddressId: 0,
          Street1: this.checkoutForm.value.streetAddress1 || this.userService.shippingAddress.Street1 || "",
          Street2: this.checkoutForm.value.streetAddress2 || this.userService.shippingAddress.Street2 || "",
          Street3: "",
          PostalCode: this.checkoutForm.value.postcode ||
            this.userService.shippingAddress.PostalCode ||
            "84043",
          City: this.checkoutForm.value.city || this.userService.shippingAddress.City || "",
          Region: this.checkoutForm.value.state || "UT",
          CountryCode: this.configService.commonData.selectedCountry,
        },
        PriceGroup: this.checkoutForm.value.customerType,
        OrderType: 1,
        KitItems: [],
        Items: productdetails,
        PartyId: 0,
        CouponCodes: [],
        CountryCode: this.configService.commonData.selectedCountry || "us",
        CustomShippingAddress: [{
          Line1: this.checkoutForm.value.streetAddress1 || this.userService.shippingAddress.Street1 || "",
          Line2: this.checkoutForm.value.streetAddress2 || this.userService.shippingAddress.Street2 || "",
          Zip:
            this.checkoutForm.value.postcode ||
            "",
          City: this.checkoutForm.value.city || "",
          StateCode: this.checkoutForm.value.state ||
            "UT",
          CountryCode: this.configService.commonData.selectedCountry || "Us",
        }]
      },
    };
    this.apiService.calculateCustomerOrderTotal(CustomerRequest).subscribe(data => {
      this.customerTotalOrder = data.Data
      this.itemData.forEach((element: any) => {
        if (this.customerTotalOrder.Items[0].ItemId === element.ItemID) {
          this.productname = element.ProductName;
          this.productSubTotal = this.customerTotalOrder.SubTotal
          this.productShippingTotal = this.customerTotalOrder.ShippingTotal
          this.productTaxTotal = this.customerTotalOrder.TaxTotal
          this.productTotal = this.customerTotalOrder.Total
        }
      });
      this.getMerchantId();
    })
  }
  getMerchantId() {
    const request = {
      countrycode: this.configService.commonData.selectedCountry || "us",
    };
    this.apiService.getPaymentMerchants(request).subscribe(data => {
      this.MerchantData = data.Data
    })
  }
  addMerchantPayment(data: any) {
    this.merchantId = data.MerchantId
  }
  onsubmitCreateCustomer() {

    const productdetails = [];
    productdetails.push({
      ItemId: this.customerTotalOrder.Items[0].ItemId,
      Quantity: this.customerTotalOrder.Items[0].Quantity || 1,
      CurrencyCode: this.companyService.selectedCurrency?.CurrencyCode,
      IsReward: false,
    });
    const request = {
      "Order": {
        "ShipMethodID": this.customerTotalOrder.ShippingMethods[0].ShipMethodId,
        "StoreID": this.storeId,
        "CurrencyCode": this.companyService.selectedCurrency?.CurrencyCode,
        "Items": productdetails,
        "Payments": [
          {
            "Amount": this.customerTotalOrder.Total,
            "SavePaymentMethodId": "",
            "CurrencyCode": this.companyService.selectedCurrency?.CurrencyCode,
            "MerchantId": this.merchantId
          }
        ],
        "CouponCodes": [
        ]
      },
      "AutoShip": null,
      "AssociateID": "",
      "AcceptTerms": true,
      "FirstName": this.checkoutForm.value.firstName,
      "LastName": this.checkoutForm.value.lastName,
      "TaxID": "",
      "BirthDate":
        new Date(
          (this.userService.personalInfo?.birthYear || 2000),
          (this.userService.personalInfo?.birthMonth || 1),
          (this.userService.personalInfo?.birthDay || 1),
        ).toDateString(),
      "PrimaryPhone": this.checkoutForm.value.phone,
      "SecondaryPhone": this.checkoutForm.value.phone,
      "Email": this.checkoutForm.value.email,
      "Username": this.checkoutForm.value.email,
      "Password": this.checkoutForm.value.password,
      "LanguageCode": "en",
      "ApplicantAddress": {
        "AddressId": 0,
        "Street1": this.checkoutForm.value.streetAddress1,
        "Street2": this.checkoutForm.value.streetAddress2,
        "Street3": "",
        "PostalCode": this.checkoutForm.value.postcode,
        "City": this.checkoutForm.value.city,
        "Region": this.checkoutForm.value.state,
        "CountryCode": "us"
      },
      "ShippingAddress": {
        "AddressId": 0,
        "Street1": this.checkoutForm.value.streetAddress1,
        "Street2": this.checkoutForm.value.streetAddress2,
        "Street3": "",
        "PostalCode": this.checkoutForm.value.postcode,
        "City": this.checkoutForm.value.city,
        "Region": this.checkoutForm.value.state,
        "CountryCode": "us"
      },
      "AssociateTypeID":this.checkoutForm.value.customerType,
      "SponsorID": this.userService.enrollerInfo?.CustomerId,
      "WebPageURL": this.checkoutForm.value.userWebalias
    }
    if(this.checkoutForm.value.MerchantId<=0){
      this.notificationService.error('error_', "Select payment mode")
      return;
    }
    if (!this.checkoutForm.valid) {
      this.notificationService.error('error_', "Please fill all the required fields")
      return;
    }
    this.apiService.submitEnrollmentForm(request).subscribe(res => {
      sessionStorage.setItem("SubmitApplication", JSON.stringify(res))
      localStorage.setItem("SubmitApplication", JSON.stringify(res))
      console.log("check the on submit res  ", res)
      if (res) {
        const iframeData = res.Data.Order.Payment.PaymentUrl
        this.getpaymentMethodIframe(iframeData)
      } else {
        this.notificationService.error(
          "error_",
          this.translate.instant(
            "unifiedapplication_error_occured_try_again",
          ),
        );
      }

    }, (err) => {
      console.error(err);

      this.notificationService.error(
        "error_",
        this.translate.instant(
          "unifiedapplication_error_occured_try_again",
        ),
      );
    }
    )
  }
  checkpassword() {
    const password = this.checkoutForm.get('password')?.value;
    const confirmPassword = this.checkoutForm.get('confirmPassword')?.value;
    // Update the passwordMismatch flag
    this.passwordMismatch = password !== confirmPassword && !!confirmPassword;
  }
  validatewebalias(request: any) {
    if (!request) {
      return;
    }
    this.apiService.validateWebAlias(request).subscribe((res: any) => {
      if (res.Data) {
        this.notificationService.success("success", "Webalias Available")
      } else {
        this.notificationService.error(
          "error_",
          "webalias Not Available"
        );
        this.checkoutForm.value.userWebalias = "";
        this.checkoutForm.controls['userWebalias'].reset();

      }
    },
      (error) => {
        if (error.Data && !error.Data.WebAlias) {
          this.notificationService.error(
            "error_",
            "webalias_not_exists",
          );
          return false;
        }
      },
      () => { },)
  }
  getpaymentMethodIframe(data: any) {
    this.dialog.open(HostedpaymentDialogComponent, {
      panelClass: "hosted_payment-Dialog",
      data: {
        Width: 800,
        IFrameUrl: data
      }
    });
  }
}

<builder-component
  *ngIf="!noBuilderPageForUrl"
  model="figma-imports"
  (load)="noBuilderPageForUrl = $event ? false : true"
  (error)="noBuilderPageForUrl = true"
  [data]="data"
  [options]="options"
  [context]="context"
>
  <div class="spinner"></div>
</builder-component>
import { ShoppingCartService } from "./shopping-cart.service";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, BehaviorSubject, Subscriber } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map } from "rxjs/operators";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { RestApiService } from "./restapi.service";
import { UserService } from "./user.service";
import * as _ from "lodash";
import { UtilityService } from "./utility.service";
import { CompanyService } from "./company.service";
import { ConfigService } from "./config.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";

// Get product from Localstorage
const products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public catalogMode: boolean = false;
  public allItems;
  public topSellingItems;
  private url1: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts: BehaviorSubject<any> = new BehaviorSubject([]);
  public observer: Subscriber<unknown>;
  public selectedPacks: any = [];
  public selectedOrderItems: any = [];
  public selectedAutoOrderItems: any = [];
  public packs = [];
  public orders = [];
  public autoship = [];
  public IsAllowDynamicCouponcode: boolean = true;
  previousRequest = {};
  previousRequestForTopSellingItems = {};
  retailStoreCategories = [];

  userServiceModal: UserServiceModal;
  @Output() updateProductDetailPricing: EventEmitter<any> = new EventEmitter<any>();
  // allPriceGroupAndStoreData = JSON.parse(sessionStorage.getItem('allPriceGroupAndStoreData') || '[]') || [];
  allPriceGroupAndStoreData = [];

  constructor(
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
    public apiService: RestApiService,
    public user: UserService,
    public utilityService: UtilityService,
    public companyService: CompanyService,
    public configService: ConfigService,
    public shoppingCartService: ShoppingCartService,
  ) {
    this.compareProducts.subscribe((products) => {
      console.log("compareProducts", products);
    });
    this.userServiceModal = this.user.userServiceModal;
    this.init();
  }

  private products(req?, saveItems = true): Observable<any> {

    const internalRequest = {
      PriceGroup: req?.PriceGroup || this.userServiceModal.customerTypeID || 2,
      StoreID: req?.StoreID ||
      this.shoppingCartService.getShoppingCart(1)[0]?.StoreID ||
      (this.userServiceModal.customerTypeID == 2 ? 3 : 2)
    };

    /* ---------------------------------------
      * Return Data from Storage if we have it.
      --------------------------------------- */

      if(this.isPriceGroupStoreIDDataAvailableInStorage(internalRequest)) {
        const DataItems = this.isPriceGroupStoreIDDataAvailableInStorage(internalRequest)?.DataItems || [];
        if(DataItems.length > 0) {
          const itemsStream = new Observable((observer) => {
            observer.next(DataItems);
            observer.complete();
          });
          return itemsStream as Observable<any>;
        }
      }

    /**-------------------------------------- */

    const request = {
      CurrencyCode:
        req?.CurrencyCode ||
        this.companyService.selectedCurrency?.CurrencyCode ||
        "USD",
      LanguageCode:
        req?.LanguageCode ||
        this.configService.commonData.selectedLanguage ||
        "en",
      RegionID:
        req?.RegionID ||
        this.companyService.getRegionID(
          this.userServiceModal?.selectedCountry || sessionStorage.getItem('selectedCountry')
        ) ||
        this.companyService.getRegionID(
          sessionStorage.getItem("selectedCountry"),
        ) ||
        1,
      PriceGroup: internalRequest.PriceGroup,
      StoreID: internalRequest.StoreID,
      CategoryId: req?.CategoryId,
    };
    return this.apiService.getItemsbyFilter(request).pipe(
      map((items) => {

        const newResult = items.Data;

        // Insert Data into Session Storage
        if(saveItems) {
          this.InsertItemsIntoSessionStorage(internalRequest, newResult);
        }

        this.allItems = newResult;
        return newResult;
      }),
    );
  }

  private isPriceGroupStoreIDDataAvailableInStorage({ PriceGroup, StoreID }) {
    const getAllItemsMapping = this.allPriceGroupAndStoreData || [];
    // const getAllItemsMapping = JSON.parse(sessionStorage.getItem('allPriceGroupAndStoreData') ||  '[]');
    return getAllItemsMapping.find((data: any) => data?.priceGroup == PriceGroup && data?.storeId == StoreID) || null;
  }

  private InsertItemsIntoSessionStorage(internalRequest, newResult) {
          
    if(this.allPriceGroupAndStoreData.find((data: any) => data?.priceGroup == internalRequest.PriceGroup && data?.storeId == internalRequest.StoreID)) {
      // If already have with same configuration just Replace it.
      this.allPriceGroupAndStoreData.forEach((item: any) => {
        if( item?.priceGroup == internalRequest.PriceGroup && item?.storeId == internalRequest.StoreID) {
          item['DataItems'] = newResult;
        }
      });  
    }  else {
      this.allPriceGroupAndStoreData.push({
        priceGroup: internalRequest.PriceGroup,
        storeId: internalRequest.StoreID,
        DataItems: newResult
      });
    }

    // try {
    //   sessionStorage.setItem('allPriceGroupAndStoreData', JSON.stringify(this.allPriceGroupAndStoreData));
    // } catch (error) {
    //   if(error.message.includes("exceeded the quota")) {
    //     //  Cleared first 2 records.
    //     this.allPriceGroupAndStoreData.splice(0, 2);
    //     sessionStorage.setItem('allPriceGroupAndStoreData', JSON.stringify(this.allPriceGroupAndStoreData));
    //   }
    // }
  }

   // Get Products By category
   public getProductByCategory(request?, saveItems?): Observable<any[]> {


  /* ---------------------------------------
  * Return Data from Storage if we have it.
  --------------------------------------- */

    if(request.PriceGroup && request.StoreID && this.isPriceGroupStoreIDDataAvailableInStorage(request)) {
      this.allItems = this.isPriceGroupStoreIDDataAvailableInStorage(request)?.DataItems || [];
    } else {
      this.allItems = [];
    }

  /**-------------------------------------- */


    if (
      this.allItems &&
      this.allItems.length > 0 
    ) {
      const itemsStream = new Observable((observer) => {
        observer.next(this.allItems);
        observer.complete();
      });
      return itemsStream as Observable<any>;
    } 
    
    return this.products(request, saveItems);
    
  }

  public banners(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url);
  }

  // Get Banners
  public getBanners() {
    return this.banners();
  }

  // Get Banners
  public getProducts(request?): Observable<Array<DSProduct>> {
    this.previousRequest = this.previousRequest || request;

    /* ---------------------------------------
    * Return Data from Storage if we have it.
    --------------------------------------- */

    if(request.PriceGroup && request.StoreID && this.isPriceGroupStoreIDDataAvailableInStorage(request)) {
      this.allItems = this.isPriceGroupStoreIDDataAvailableInStorage(request)?.DataItems || [];
    } else {
      this.allItems = [];
    }

  /**-------------------------------------- */

  if (
    this.allItems &&
    this.allItems.length > 0 &&
    _.isEqual(this.previousRequest, request)
    ) {
      const itemsStream = new Observable((observer) => {
        observer.next(this.allItems);
        observer.complete();
      });
      return itemsStream as Observable<any>;
    } else {
      return this.products(request);
    }
  }
  public getRelatedProducts(product: DSProduct, NumberOfProducts?, customStoreID?): Observable<any[]> {

    const request = {
      CategoryId: product.CategoryId,
      NumberOfProducts: NumberOfProducts || 5,
      CurrencyCode: this.companyService.selectedCurrency.CurrencyCode,
      LanguageCode: this.configService.commonData.selectedLanguage || "en",
      RegionID: this.companyService.getRegionID(
        this.configService.commonData?.selectedCountry,
      ),
      PriceGroup: this.userServiceModal.customerTypeID,
      StoreID: customStoreID ?? this.shoppingCartService.getShoppingCart(1)[0]?.StoreID,
      CountryCode: this.configService.commonData.selectedCountry,
    };

    if(this.topSellingItems && this.topSellingItems.length > 0 && 
      _.isEqual(this.previousRequestForTopSellingItems, request)) {
      const topItemsStream = new Observable((observer) => {
        observer.next(this.topSellingItems);
        observer.complete();
      });
      return topItemsStream as Observable<any>;
    }

    this.previousRequestForTopSellingItems = _.cloneDeep(request);

    return this.apiService.getTopSellingProducts(request).pipe(
      map((items) => {
        this.topSellingItems = items.Data;
        return this.topSellingItems;
      }),
    );
  }

  // Get Products By Id
  public getProduct(id: number | string, customRequest?: any): Observable<any> {

    /* ---------------------------------------
    * Return Data from Storage if we have it.
    --------------------------------------- */

    if(customRequest.PriceGroup && customRequest.StoreID && this.isPriceGroupStoreIDDataAvailableInStorage(customRequest)) {
      this.allItems = this.isPriceGroupStoreIDDataAvailableInStorage(customRequest)?.DataItems || [];
    } else {
      this.allItems = [];
    }

  /**-------------------------------------- */
    
    if (this.allItems && this.allItems.length > 0) {
      const product = this.allItems.find((item: any) => {
        return item.ItemID?.toLowerCase() == id?.toString()?.toLowerCase();
      });
      const itemsStream = new Observable((observer) => {
        observer.next(product);
        observer.complete();
      });
      return itemsStream as Observable<any>;
    } else {
      return this.products(customRequest).pipe(
        map((items) => {
          const products = items;
          return products.find((item: any) => {
            return item.ItemID?.toLowerCase() == id?.toString()?.toLowerCase();
          });
        }),
      );
    }
  }

  /*
---------------------------------------------
----------  Compare Product  ----------------
---------------------------------------------
*/

  // Get Compare Products
  public getComapreProducts(): Observable<any[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return itemsStream as Observable<DSProduct[]>;
  }

  // If item is aleready added In compare
  public hasProduct(product): boolean {
    const item = products.find((item) => item.Id === product.id);
    return item !== undefined;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<any> {
    return this.products().pipe(
      map((items) => {
        return items.find((item: any) => {
          return item.name.replace(" ", "-") === slug;
        });
      }),
    );
  }

  // Add to compare
  public addToCompare(product) {
    let message, status;
    let item = false;
    if (this.hasProduct(product)) {
      item = products.filter((item) => item.id === product.id)[0];
      this.snackBar.open(
        "The product  " + product.name + " already added to comparison list.",
        "×",
        { panelClass: "error", verticalPosition: "top", duration: 3000 },
      );
    } else {
      if (products.length < 4) {
        products.push(product);
      }
      message =
        "The product " + product.name + " has been added to comparison list.";
      status = "success";
      this.snackBar.open(message, "×", {
        panelClass: [status],
        verticalPosition: "top",
        duration: 3000,
      });
    }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
  }

  // Removed Product
  public removeFromCompare(product) {
    if (product === undefined) {
      return;
    }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }

 

  private init() {
    if (localStorage.getItem("cart.packs")) {
      this.selectedPacks = JSON.parse(localStorage.getItem("cart.packs"));
    }
    if (localStorage.getItem("cart.order")) {
      this.selectedOrderItems = JSON.parse(localStorage.getItem("cart.order"));
    }
    if (localStorage.getItem("cart.autoship")) {
      this.selectedAutoOrderItems = JSON.parse(
        localStorage.getItem("cart.autoship"),
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-become-a-distributor',
  templateUrl: './become-a-distributor.component.html',
  styleUrls: ['./become-a-distributor.component.sass']
})
export class BecomeADistributorComponent implements OnInit {

  // Builder.io

  noBuilderPageForUrl: boolean = false;

  data = {
    serviceName: this.userService.dummyName
  };
  
  context = {
    servicecontextName: this.userService.dummyName,
    changeName: () => {
      this.userService.dummyName = Math.random().toFixed(4).toString();
    },
  };

  options: any = {
    cacheSeconds: 0.1,
    data: {
      locale: "en-US",
    },
  };

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

}

<div class="main_component">
    <div class="top_div">

        <h1 *ngIf="this.ItemId=='starterpack'" class="top_Heading">Starter's Pack <span
                class="highlight">Checkout</span></h1>
        <h1 *ngIf="this.ItemId=='builderpack'" class="top_Heading">Builder's Pack <span
                class="highlight">Checkout</span></h1>

    </div>
    <div class="checkout-form">

        <div class="login-prompt">
            <p>
                Returning customer? <a (click)="showlogint()">Click here to login</a>
            </p>
        </div>
        <div *ngIf="loginDiv">

            <mat-card class="m-b-20">
                <mat-card-content class="cart_content">
                    <div>
                        <span>
                            If you have shopped with us before, please enter your details below. If you are a new
                            customer, please proceed to the Billing section.
                        </span>

                    </div>
                    <form [formGroup]="loginForm">
                        <div>
                            <div fxLayout="row">
                                <div class="m-r-15" fxFlex="50">
                                    <mat-form-field class="col-6" appearance="outline">
                                        <mat-label>UserName or Email</mat-label>
                                        <input matInput formControlName="UserNameEmail" placeholder="" />
                                        <mat-error *ngIf="loginForm.get('UserNameEmail')?.hasError('required')">
                                            UserName or Email is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="50 ">
                                    <mat-form-field class="col-6" appearance="outline">
                                        <mat-label>Password</mat-label>
                                        <input class="noBorder" [type]="showloginPassword ? 'text' : 'password'"
                                            formControlName="loginPassword" matInput validate noempty="true"
                                            placeholder="Password">
                                        <button mat-icon-button matSuffix type="button" style="background:#252b62;"
                                            (click)="toggleloginPasswordVisibility()">
                                            <mat-icon>{{ showloginPassword ? 'visibility' : 'visibility_off'
                                                }}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="loginForm.get('loginPassword')?.hasError('required')">
                                            Password is required
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="loginPassword"  />
                        <mat-error *ngIf="loginForm.get('loginPassword')?.hasError('pattern')">
                            Password is required
                        </mat-error>
                    </mat-form-field> -->
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div class="m-r-15" fxFlex="20">
                                    <button class="col-2 loginbtn" mat-raised-button color="primary"
                                        (click)="loginUser()">Login</button>
                                </div>
                                <div fxFlex="80">
                                    <mat-checkbox class="col-3 txtRem" formControlName="rememberPass">Remember
                                        Me</mat-checkbox>
                                </div>
                            </div>
                            <div>
                                <a href="#">
                                    Lost your password ?
                                </a>
                            </div>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>

        <form [formGroup]="checkoutForm" class="checkout-form">
            <div class="customerTypeForm">
                <mat-radio-group aria-label="Customer Type" fxLayout="column" formControlName="customerType">
                    <mat-label class="customerType">
                        Customer Type
                    </mat-label>
                    <div class="customerRadioType">
                        <mat-radio-button (click)="getItembyFilter(this.checkoutForm.value.customerType=1)"
                            class="radio_btnCustomer" value="1">Member</mat-radio-button>
                        <mat-radio-button (click)="getItembyFilter(this.checkoutForm.value.customerType=2)"
                            value="2">Retail Customer</mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
            <mat-card class="m-b-20">
                <mat-card-header class="billing_details">
                    <mat-card-title>Billing Details</mat-card-title>
                </mat-card-header>
                <mat-card-content class="cart_content">
                    <div fxLayout="row">
                        <div class="m-r-15" fxFlex="50">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="firstName" placeholder="First name" />
                                <mat-error *ngIf="checkoutForm.get('firstName')?.hasError('required')">
                                    First Name is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50 ">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="lastName" placeholder="Last name" />
                                <mat-error *ngIf="checkoutForm.get('lastName')?.hasError('required')">
                                    Last Name is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Email Address</mat-label>
                            <input matInput formControlName="email" placeholder="Email address" email="true"
                                noempty="true" validate nospaceallow="true" checkOutSection="true"
                                (focusout)="verifyUserNameAndEmail(checkoutForm.value.email)" />
                            <mat-error *ngIf="checkoutForm.get('email')?.hasError('pattern')">
                                Invalid Email Address
                            </mat-error>
                            <mat-error *ngIf="checkoutForm.get('email')?.hasError('required')">
                                Email Address is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Country/Region</mat-label>
                            <mat-select formControlName="country">
                                <mat-option *ngFor="let region of region" [value]="region.ID">
                                    {{region.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <label for="streetAddress">
                            Street Address
                        </label>
                        <mat-form-field appearance="outline">
                            <!-- <mat-label>Street Address</mat-label> -->
                            <input matInput formControlName="streetAddress1"
                                placeholder="House number and street name" />
                            <mat-error *ngIf="checkoutForm.get('streetAddress1')?.hasError('required')">
                                Address is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <!-- <mat-label>Apartment, suite, unit, etc. (optional)</mat-label> -->
                            <input matInput formControlName="streetAddress2"
                                placeholder=" Apartment, suite, unit, etc. (optional)" />
                        </mat-form-field>
                    </div>



                    <mat-form-field appearance="outline">
                        <mat-label>Town/City</mat-label>
                        <input matInput formControlName="city" placeholder="Town/City" />
                        <mat-error *ngIf="checkoutForm.get('city')?.hasError('required')">
                            Town/City is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>State/County</mat-label>
                        <mat-select formControlName="state" (selectionChange)="calculateCustomerTotalOrder()">
                            <mat-option *ngFor="let state of states" [value]="state.StateCode">
                                {{state.StateName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Postcode/ZIP</mat-label>
                        <input matInput formControlName="postcode" placeholder="Postcode/ZIP"
                            (focusout)="checkZipValidate()" />
                        <mat-error *ngIf="checkoutForm.get('postcode')?.hasError('required')">
                            Postcode/ZIP is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone" placeholder="Phone" />
                        <mat-error *ngIf="checkoutForm.get('phone')?.hasError('pattern')">
                            Invalid Phone Number
                        </mat-error>
                        <mat-error *ngIf="checkoutForm.get('phone')?.hasError('required')">
                            Phone Number is required
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>

            </mat-card>
            <mat-card class="m-b-20">
                <mat-card-content class="cart_content">
                    <mat-form-field appearance="outline">
                        <mat-label>Account Username</mat-label>
                        <input readonly matInput formControlName="username" placeholder="Username" />
                        <!-- <mat-error *ngIf="checkoutForm.get('username')?.hasError('required')">
                            Username is required
                        </mat-error> -->
                    </mat-form-field>
                    <div fxLayout="row">
                        <div class="m-r-15" fxFlex="50">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>Create Password</mat-label>
                                <input class="noBorder" [type]="showPassword ? 'text' : 'password'"
                                    formControlName="password" matInput validate noempty="true" placeholder="Password">
                                <button mat-icon-button matSuffix type="button" style="background:#252b62;"
                                    (click)="togglePasswordVisibility()">
                                    <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="checkoutForm.get('password')?.hasError('minlength')">
                                    Password must be at least 6 characters
                                </mat-error>
                                <mat-error *ngIf="checkoutForm.get('password')?.hasError('required')">
                                    Password is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50 ">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>Confirm Password</mat-label>
                                <input class="noBorder" [type]="showConfirmPassword ? 'text' : 'password'" matInput
                                    formControlName="confirmPassword" placeholder="Password"
                                    (focusout)="checkpassword()" />
                                <button mat-icon-button matSuffix type="button" style="background:#252b62;"
                                    (click)="toggleConfirmPasswordVisibility()">
                                    <mat-icon>{{ showConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="checkoutForm.get('confirmPassword')?.hasError('required')">
                                    Confirm Password is required
                                </mat-error>
                                <mat-error *ngIf="checkoutForm.get('confirmPassword')?.hasError('passwordMismatch')">
                                    Password does not match.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>Enter Webalias</mat-label>
                        <input matInput formControlName="userWebalias"
                            (focusout)="validatewebalias(checkoutForm.value.userWebalias)" placeholder="Webalias" />
                        <mat-error *ngIf="checkoutForm.get('userWebalias')?.hasError('required')">
                            Webalias is required
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>

            <mat-card class="m-b-20">
                <mat-card-header class="billing_details">
                    <mat-card-title>Ship to a Different Address?</mat-card-title>
                </mat-card-header>
                <mat-card-content class="cart_content">
                    <mat-form-field appearance="outline">
                        <mat-label>Order Notes (optional)</mat-label>
                        <textarea class="noBorder" matInput formControlName="OrderNote"></textarea>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card class="m-b-20">
                <mat-card-header class="billing_details">
                    <mat-card-title>Payment Method</mat-card-title>
                </mat-card-header>

                <mat-card-content class="cart_content">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="MerchantId">
                            <mat-option value="0">
                                Please Select Payment Method
                            </mat-option>
                            <mat-option (click)="addMerchantPayment(merchant)" *ngFor="let merchant of MerchantData"
                                [value]="merchant.MerchantId">
                                {{merchant.DisplayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card class="m-b-20">
                <mat-card-header class="billing_details">
                    <mat-card-title>Your Order</mat-card-title>
                </mat-card-header>
                <mat-card-content class="cart_content">
                    <table class="order-table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th class="yourorder_subtotal"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {{this.productname}} × 1
                                </td>
                                <td>${{this.productSubTotal||0}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Subtotal</td>
                                <td>${{this.productSubTotal||0}}</td>
                            </tr>
                            <tr>
                                <td>Shipping</td>
                                <td>${{this.productShippingTotal}}</td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td>${{this.productTaxTotal}}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td><strong>${{this.productTotal}}</strong></td>
                            </tr>
                        </tfoot>

                    </table>
                </mat-card-content>
            </mat-card>
            <!-- <mat-card class="m-b-20">
                <mat-card-header>
                    <mat-card-title class="credit_card_Details">
                        Credit Card (XL NMI) <span class="img_title">
                            <img
                            src="https://5starsuccesscoaching.com/wp-content/plugins/woofunnels-woocommerce-nmi-gateway/lib/skyverge/woocommerce/payment-gateway/assets/images/card-visa.svg"
                            alt="visa"
                            class="sv-wc-payment-gateway-icon wc-nmi-gateway-woocommerce-credit-card-payment-gateway-icon"
                            width="40" height="25" style="width: 40px; height: 25px;"><img
                            src="https://5starsuccesscoaching.com/wp-content/plugins/woofunnels-woocommerce-nmi-gateway/lib/skyverge/woocommerce/payment-gateway/assets/images/card-mastercard.svg"
                            alt="mastercard"
                            class="sv-wc-payment-gateway-icon wc-nmi-gateway-woocommerce-credit-card-payment-gateway-icon"
                            width="40" height="25" style="width: 40px; height: 25px;"><img
                            src="https://5starsuccesscoaching.com/wp-content/plugins/woofunnels-woocommerce-nmi-gateway/lib/skyverge/woocommerce/payment-gateway/assets/images/card-amex.svg"
                            alt="amex"
                            class="sv-wc-payment-gateway-icon wc-nmi-gateway-woocommerce-credit-card-payment-gateway-icon"
                            width="40" height="25" style="width: 40px; height: 25px;"><img
                            src="https://5starsuccesscoaching.com/wp-content/plugins/woofunnels-woocommerce-nmi-gateway/lib/skyverge/woocommerce/payment-gateway/assets/images/card-discover.svg"
                            alt="discover"
                            class="sv-wc-payment-gateway-icon wc-nmi-gateway-woocommerce-credit-card-payment-gateway-icon"
                            width="40" height="25" style="width: 40px; height: 25px;">
                        </span>
                        

                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="cart_content">
                    <div>

                        <mat-form-field appearance="outline">
                            <mat-label>Card Number</mat-label>
                            <input matInput formControlName="cardNumber" placeholder="Card Number" />
                        </mat-form-field>
                    </div>
<div class="row">

    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Expiry (MM/YY)</mat-label>
        <input matInput formControlName="cardExpiry" placeholder="MM/YY" />
    </mat-form-field>
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Card Code (CVC)</mat-label>
        <input matInput formControlName="cardCode" placeholder="CVC" />
    </mat-form-field>
</div>
                </mat-card-content>
            </mat-card> -->

            <div class="place-order">
                <button mat-raised-button color="primary" type="submit" (click)="onsubmitCreateCustomer()">
                    Place Order
                </button>
            </div>

        </form>
    </div>
</div>
import { Component, OnInit, Input } from "@angular/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "src/app/components/shared/services/config.service";
import { ProductDialogComponent } from "src/app/components/shop/products/product-dialog/product-dialog.component";
import { Cart1Service } from "../services/cart1.service";
import { ItemsListService } from "../services/itemsList.service";
import { CompanyService } from "../services/company.service";
import { ProductService } from "../services/product.service";

@Component({
  selector: "app-pack-carousel",
  templateUrl: "./pack-carousel.component.html",
  styleUrls: ["./pack-carousel.component.scss"],
})
export class PackCarouselComponent implements OnInit {
  
  @Input("products") products;
  @Input("type") type: string;
  public config: SwiperConfigInterface = {};
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    public cart1Service: Cart1Service,
    public itemListService: ItemsListService,
    public companyService: CompanyService,
    public itemsService: ProductService,
  ) {}
  public checkItem : boolean;

  ngOnInit() {
    this.config = {
      observer: true,
      slidesPerView: 3.1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    };    
  }

  public openProductDialog(product) {
    this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
    });
  }

  // Add to cart
  public addToCart(product, type) {
    if (type == "pack") {
      // enroll store id 4 in this base code case
      this.cart1Service.addToCart(product, true, "", false, false, true, true);
      this.cart1Service.addAOScanToCart.emit();
    } else if (type == "autoship") {
      // autoship store id 5 in this base code case
      // this.cart1Service.addToCart(product, true, "", true, false, false, true);

      this.addToBothCart(product);

    } else if (type == "order") {
      // initial order store id 2 in this base code case
      this.cart1Service.addToCart(product, true, "", false, false, false, true);
    }
  }

  addToBothCart(product) {

    if(product.ItemID == 'e97') {

      const inInCart = this.itemsService.selectedOrderItems.find(i => i.ItemID == product.ItemID);
      const inInAutoshipCart = this.itemsService.selectedAutoOrderItems.find(i => i.ItemID == product.ItemID);
  
      if(!inInAutoshipCart) {
        this.cart1Service.addToCart(product, true, "", true, false, false, true);
      }
  
      if(!inInCart) {
        this.cart1Service.addToCart(product, true, "", false, false, false, true);
      }

      return;
    }

    // Autoship
    this.cart1Service.addToCart(product, true, "", true, false, false, true);

    // Order
    this.cart1Service.addToCart(product, true, "", false, false, false, true);
  }


   public isAOScanInCart() {

    if(this.type == 'autoship') {
      const item = this.itemsService.selectedAutoOrderItems.find(i => i.ItemID == 'e97');
      return item;
    }

    return false;
  }

  
}

<main class="container min-500" id="site-content">
    <div fxLayout="row" flex class="m-t-15 forgotPassword">
        <div layout-padding flex="100" class="bg-c-md-content mat-content">
            <mat-card>
                <mat-card-title class="b-b titleHeader">
                    <span class="md-headline f-s-24" translate="reset_password"></span>
                </mat-card-title>
                <mat-card-actions layout="column"  fxLayoutAlign="start stretch" class="p-l-10">
                    <div fxLayout="column" fxFlex="100">
                        <div fxLayout="column"  fxLayoutAlign="start start">
                            <form class="forgot_pass_form" id="forgot_pass_form" name="ForgotPassword" *ngIf="!sentEmail">
                                <p translate="forgot_password_option_description" class="m-b-20 color-f-s-16"></p>
                                <p translate="forgot_password_note" class="m-b-20 color-f-s-16"></p>
                                <mat-radio-group [(ngModel)]="selectedForgotPasswordOption.option" [ngModelOptions]="{standalone: true}" name="forgotpassword" aria-labelledby="example-radio-group-label" class="example-radio-group">
                                    <div class="forgetPasswordOptions" *ngFor="let opt of forgotPasswordOptions">
                                        <label class="control-group ds-radio">
                                            <mat-radio-button  fxLayout="row" class="example-radio-button" (click)="forgotPasswordData.userName=''" [value]="opt.option">
                                             <span [hidden]="selectedForgotPasswordOption.option==opt.option">{{opt.description | translate}}</span>
                                            </mat-radio-button>
                                        </label>
                                        <div class="fieldBox m-l-10" *ngIf="selectedForgotPasswordOption.option==opt.option">
                                            <mat-form-field class="ds-validate md-block" appearance="outline">
                                                <mat-label class="p-l-15" *ngIf="selectedForgotPasswordOption.option == 1" translate="resetpassword_option_username"></mat-label>
                                                <mat-label class="p-l-15" *ngIf="selectedForgotPasswordOption.option == 2" translate="resetpassword_option_email"></mat-label>
                                                <mat-label class="p-l-15" *ngIf="selectedForgotPasswordOption.option == 3" translate="resetpassword_option_customerid"></mat-label>
                                                <input forgotsection="true" class="email p-l-10" id="fgp_input" matInput  validate noempty="true" [attr.email]="selectedForgotPasswordOption.option == 2" [(ngModel)]="forgotPasswordData.userName" [ngModelOptions]="{standalone: true}">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-radio-group>
                            </form>
    
                            <!-- Email Sent -->
                            <div *ngIf="sentEmail" class="account_recovery">
                                <h2 translate="thank_you_"></h2>
                                <div class="m-t-20 m-b-15 account_recovery_msg_1" translate="account_recovery_msg_1"></div>
                                <div class="m-b-20 account_recovery_msg_2" translate="account_recovery_msg_2"></div>
                                <a mat-button mat-raised-button color="primary" type="submit" class="m-b-15 m-t-5" [routerLink]="['/login']"> {{ "back_to_login" | translate}}</a>
                            </div>
                        </div>
                        <div *ngIf="!sentEmail">
                            <button class="resetButton" (success)="emailSentForgotPassword()" validate trigger-check="true" >{{"reset_"|translate}}</button>
                        </div>
                    </div>
                    <div>
                    </div>
                </mat-card-actions>
            </mat-card>
            <br>
        </div>
    </div>
</main>
<section id="FindSponsorModal">

  <button mat-icon-button class="close-button" (click)="dialogClose()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  
  <div mat-dialog-content>
    <form class="example-form">
      <div fxLayout="column">
  
        <div class="main-section" fxLayout="column" fxLayoutGap="25px">
          <div class="text-center f-w-700 w-100">Choose Sponsor</div>
          <p class="text-center" translate="Please_enter_enroller"></p>
          <input type="text" placeholder="ID or Name"  [(ngModel)]="searchText" [formControl]="myControl"/>
          <button class="btn-search" fxLayoutAlign="center center" (click)="searchEnroller(searchText)">
            <mat-icon>search</mat-icon>
            <div>Search</div>
          </button>
        </div>
   
        <!-- <div fxFlex="100" class="searchInput">
          <mat-form-field fxFlex>
            <input type="text" aria-label="Number" [(ngModel)]="searchText" matInput [formControl]="myControl">
            <button mat-button matSuffix (click)="searchEnroller(searchText)">
              <mat-icon matSuffix class="arrow-icon">arrow_right_alt</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
      </div>
    </form>
    <div class="enroller-data-table" *ngIf="filterData.length > 0" [style.visibility]="!showSponsorTable ? 'collapse' : 'visible'">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="SelectEnroller">
          <th mat-header-cell *matHeaderCellDef>
            <!-- Select Sponsor -->
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? singleSelection($event, row) : null"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row) == true">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- NAME Column -->
        <ng-container matColumnDef="ProfileImageUrl">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let element" class="avtar">
            <img [src]="configService.localSettings.Disco.DiscoUrl+element.ProfileImageUrl"
              [default]="'assets/img/noimage.png'" />
          </td>
        </ng-container>
        <!-- FirstName Column -->
        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong> Sponsor Details </strong> </th>
          <td mat-cell *matCellDef="let element">
             {{element.FirstName}} {{element.LegalLastName}} <br>
             <strong>Member ID: </strong> {{element.BackOfficeId}}
            </td>
        </ng-container>
        <!-- LastName Column -->
        <!-- <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> LAST NAME </th>
          <td mat-cell *matCellDef="let element"> {{element.LegalLastName}} </td>
        </ng-container> -->
        <!-- BackOfficeId Column -->
        <!-- <ng-container matColumnDef="BackOfficeId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Member ID </th>
          <td mat-cell *matCellDef="let element"> {{element.BackOfficeId}}</td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="b-b p-0"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="b-b p-0">
          <td colspan="100%">
            <div class="custom-row-div">
              hello
              <!-- Add your custom content here -->
            </div>
          </td>
        </tr>
  
      </table>
      <mat-paginator #paginator [length]="itemCount" [pageSize]="10" [pageSizeOptions]="[5,10, 25, 100]"
        showFirstLastButtons *ngIf="pageSize">
      </mat-paginator>
    </div>
    <div *ngIf="filterData.length == 0 && showSponsorTable" class="sponsorText">
      Sponsor is not found
    </div>
  </div>
  
  <div *ngIf="filterData.length > 0" mat-dailog-actions class="enroller-footer">
    <div fxLayoutAlign="center center" fxLayout="row" class="p-b-15 p-t-15">
      <!-- <div fxFlex="70" flex class="d-flex">
        <p *ngIf="false"> {{"dont_have_a_referral" | translate:{Default:"Don't have a referral?"} }}
        </p>
        &nbsp;
        <a class="f-s-14 p-2" [mat-dialog-close]="true" *ngIf="false"> {{"click_here" | translate:{Default:"Click Here"}
          }}</a>
      </div> -->
      <div fxLayoutAlign="center center" [style.visibility]="!showSponsorTable ? 'collapse' : 'visible'">
        <button mat-raised-button color="primary" class="f-s-18" type="button" (click)="setSponsor();">{{
          'set_as_my_enroller' | translate:{Default:"Set as my Sponsor"} }}</button>
      </div>
    </div>
  </div>
  
  <!-- <div class="m-t-20">
    <p class="text-center">Don't have any sponsor? <span class="no-sponsor-link" (click)="handleNoSponsor()">Click here</span></p>
  </div> -->
</section>
<!-- Builder.io Code -->

<builder-component
  *ngIf="!noBuilderPageForUrl"
  model="figma-imports"
  (load)="noBuilderPageForUrl = $event ? false : true"
  (error)="noBuilderPageForUrl = true"
  [options]="options" [context]="context"
>
  <div class="spinner"></div>
</builder-component>



<!-- Builder.io Code -->



<ng-template>


  <div class="slideshow">
    <div class="video-container">
      <video autoplay [muted]="true" loop playsinline id="myVideo">
        <source src="https://cdn.shopify.com/s/files/1/0926/9298/files/ira.mp4?v=1647986268" type="video/mp4">
      </video>
      <div class="content c-white">
        <p><span class="f-s-12">INTRODUCING</span></p>
        <p class="m-t-12 f-s-36 f-w-700">The Perfect Workout</p>
        <p class="m-t-20">Begins with the perfect stretch material</p>
        <p class="m-t-30 shop-btn"><span class="c-black f-s-14" routerLink="/products/all">Shop now</span></p>
      </div>
    </div>
  </div>

  <div class="home-1-wrap">

    <div class="">
      <!-- Shop the collection div -->
      <div fxLayout="row wrap" class="p-30">
        <div fxLayout="column" fxFill fxLayout.gt-sm="row" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px"
          fxFlex>
          <div class="card-wrap img-bg2 h-661" fxFill
            style="background-image: url('https://cdn.shopify.com/s/files/1/0926/9298/files/FORCEMAJEURE_LIGHTMARINE_4_1200x1600_ecbc368d-33f9-4ae8-ac62-c2e8205511e5.jpg?v=1644345376&width=1200');"
            fxFlex="49" fxFlex.gt-md="50" fxFlex.gt-xs="50">
            <mat-card class="light-block" fxLayout="row" fxLayoutAlign="start center">
              <div class="content c-white text-center">
                <ngx-skeleton-loader loadingText="5 Star" *ngIf="!contentLoaded" count="2"
                  [theme]="{ 'border-radius': '0', width: '100px', height: '15px' }"></ngx-skeleton-loader>
                <p *ngIf="contentLoaded" class="f-s-36 f-w-700 font-IBM">Womens</p>
                <p><span *ngIf="contentLoaded" class="m-t-12 font-IBM">Get a bonus plus for buying more that three
                    products</span></p>
                <p *ngIf="contentLoaded" class="m-t-30 shop-btn font-IBM" routerLink="/products/all"><a
                    class="c-black f-s-14">Shop the
                    Collection</a></p>
              </div>
            </mat-card>
          </div>
          <div class="card-wrap img-bg2 h-661" fxFill
            style="background-image: url('https://cdn.shopify.com/s/files/1/0926/9298/files/STREAMLINE78_MARINE7_1200x1600_22474e3e-6864-4172-a333-32b79a0ccee6.jpg?v=1644345448&width=1200');"
            fxFlex="48" fxFlex.gt-md="50" fxFlex.gt-xs="50">
            <mat-card class="light-block" fxLayout="row" fxLayoutAlign="start center">
              <div class="content c-white text-center">
                <ngx-skeleton-loader loadingText="5 Star" *ngIf="!contentLoaded" count="2"
                  [theme]="{ 'border-radius': '0', width: '100px', height: '15px' }"></ngx-skeleton-loader>
                <p *ngIf="contentLoaded" class="f-s-36 f-w-700 font-IBM">Sweat Shirts</p>
                <p><span *ngIf="contentLoaded" class="m-t-12 font-IBM">Get a bonus plus for buying more that three
                    products</span></p>
                <p *ngIf="contentLoaded" class="m-t-30 shop-btn font-IBM" routerLink="/products/6"><a
                    class="c-black f-s-14">Shop the
                    Collection</a></p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <hr style="margin:0px 28px; border-color:var(--theme-default-grey)">
      <div class="p-30">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">{{allItems[0]?.Category}}</p>
          <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
            fxLayoutGap="20px" fxFlex="100">
            <div *ngFor="let productImage of healthBeautyProduct; let i=index" fxLayout="column" fxFlex="32"
              fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i)"
                (mouseout)="mouseOut(i)" fxLayout="column" fxFill class="p-relative cursor-pointer" id="parent">
                <img (click)="goToShop(productImage.ItemID)" [src]="env.imageUrl +productImage.ImageUrl" alt=""
                  class="img-bg" [id]="'product'+i" style="width: 100% !important;">
                <!-- <img [src]="productImage.url" alt="" class="img-bg d-none" [id]="'products'+i" style="width: 100% !important;"> -->
                <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                  <div class="p-absolute abs-width">
                    <button [id]="'btn'+i" class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                      mat-raised-button color="black" (click)="handleProduct(productImage)">Quick Add</button>
                  </div>
                </div>
              </div>
              <div fxFlex="10" fxLayout="row" class="content m-t-20">
                <div fxFlex="70">
                  <p><span class="font-IBM">{{productImage.ProductName}}</span></p>
                  <p><span class="m-t-10 font-IBM"><del *ngIf="productImage.oldPrice">{{productImage.oldPrice}} </del>
                      {{'$'+productImage.Price}}</span></p>
                </div>
                <div *ngIf="productImage.oldPrice" fxFlex="30" class="text-end m-t-10">
                  <p class="f-s-12 sale" color="white">Sale</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sweat suit -->
      <div class="p-30">
        <div fxLayout="column" class="bg-white-smoke" fxFill fxLayout.gt-sm="row" fxLayoutAlign="center center" fxFlex>
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
            <img
              src="https://cdn.shopify.com/s/files/1/0926/9298/files/NEWBLACKOUTLEGGINGS_NAVY_1_085ee5dc-7e45-4d27-8a72-d75f18d4bb01_1080x.jpg?v=1600985773"
              alt="product-image" width="100%">
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center center" fxFill>
            <div class="content text-center" fxLayout="column" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center">
              <p class="m-t-12 f-s-36 f-w-700">The Sweat Suit</p>
              <p class="m-t-20">With award-winning moisture-wicking fabric.</p>
            </div>
            <div fxLayout="row" class="m-t-30" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center">
              <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center">
                <div class="img-hover text-center">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0926/9298/products/WORKOUTSPORTSBRA_BLACK_1_1200x1600_47939eb0-cf11-46fc-b6c3-a175511f4398.jpg?v=1600717663&width=650"
                    alt="sports-costume" width="40%">
                </div>
                <p class="m-t-10 text-center f-s-14">Shoal Sports Bra in Black</p>
                <p class="m-t-10 f-s-14 a-hover"><a routerLink="/products/all">Shop now</a></p>
              </div>
              <div class="vl" fxFlex="1"></div>
              <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center">
                <div class="img-hover text-center">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0926/9298/products/LIMITLESS7_8LEGGINGS_CARBON_5_1200x1600_4fd2ffb4-7ca0-4f10-b682-78ba6be776a4.jpg?v=1600723210&width=650"
                    alt="sports-costume" width="40%">
                </div>
                <p class="m-t-10 text-center f-s-14">Tributary Leggings</p>
                <p class="m-t-10 f-s-14 a-hover"><a routerLink="/products/all">Shop now</a></p>
              </div>
              <div class="vl" fxFlex="1"></div>
              <div fxFlex="33" fxLayout="column" fxLayoutAlign="center center">
                <div class="img-hover text-center">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0926/9298/products/BLACKOUTLITELEGGING_DARKGREENMARL_4_1200x1600_15f09cf0-893a-4085-af87-55b1dfaa9ce9.jpg?v=1602882712&width=650"
                    alt="sports-costume" width="40%">
                </div>
                <p class="m-t-10 text-center f-s-14">Tundra Leggings in Dark Green</p>
                <p class="m-t-10 f-s-14 a-hover"><a routerLink="/products/all">Shop now</a></p>
              </div>
              <div class="vl" fxFlex="1"></div>
            </div>
            <button class="shop-btn product-button" mat-raised-button color="black" routerLink="/products/all">Shop the
              collection</button>
          </div>
        </div>
      </div>
      <hr style="margin:0px 28px; border-color:var(--theme-default-grey)">
      <!-- Collections -->
      <div class="p-30">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">Collections</p>
          <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
            fxLayoutGap="20px" fxFlex>
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class="img-hover text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/products/LIMITLESS7_8LEGGINGS_CARBON_5_1200x1600_4fd2ffb4-7ca0-4f10-b682-78ba6be776a4_540x.jpg?v=1600723210"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-18 f-w-700 m-t-10">Leggings</p>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class="img-hover text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/products/MARVELSPORTSBRA_CARBON_1_1200x1600_115127f6-557f-43bd-bf53-8db8bc9ecea8_540x.jpg?v=1600717912"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-18 f-w-700 m-t-10">Sports Bras</p>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class="img-hover text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/products/WRPUFFA_JACKET_LIGHTGREYMARL_271_1200x1600_14b13e56-441c-44f3-abe4-68afbc11116f_540x.jpg?v=1601329276"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-18 f-w-700 m-t-10">Men's Jackets</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Performance div -->
      <div class="m-t-20">
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="50" class="bg-img p-relative">
            <img src="https://cdn.shopify.com/s/files/1/0926/9298/files/Block_02.jpg?v=1600977240&width=1512"
              alt="image">
            <div class="content p-abs c-white">
              <div class="p-relative p-content-div">
                <p><span class="f-s-12">OUR VALUES</span></p>
                <p class="m-t-12 f-s-36 f-w-700">Performance</p>
                <p class="m-t-20 w-35">Everything we do at {{'Clientname' | translate}} is guided by a commitment to
                  unlocking the highest
                  performance within our customers.</p>
                <p class="m-t-30 shop-btn" routerLink="/products/all"><a class="c-black f-s-14 text-uppercase">Learn More</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin:0px 28px; border-color:var(--theme-default-grey)">
      <!-- Quarterly Packs  this.allItems[0].Category-->
      <div class="p-30">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">{{allItems[1]?.Category}}</p>
          <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
            fxLayoutGap="20px" fxFlex="100">
            <div *ngFor="let productImage of quarterlyPacksProduct; let i=index" fxLayout="column" fxFlex="32"
              fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i+4)"
                (mouseout)="mouseOut(i+4)" fxLayout="column" fxFill class="p-relative cursor-pointer">
                <img (click)="goToShop(productImage.ItemID)" [src]="env.imageUrl +productImage.ImageUrl" alt=""
                  class="img-bg" [id]="'product'+(i+4)" style="width: 100% !important;">
                <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                  <div class="p-absolute abs-width">
                    <button [id]="'btn'+(i+4)" class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                      mat-raised-button color="black" (click)="handleProduct(productImage)">Quick Add</button>
                  </div>
                </div>
              </div>
              <div fxFlex="10" fxLayout="row" class="content m-t-20">
                <div fxFlex="70">
                  <p><span class="font-IBM">{{productImage.ProductName}}</span></p>
                  <p><span class="m-t-10 font-IBM"><del *ngIf="productImage.oldPrice">{{productImage.oldPrice}} </del>
                      {{'$'+productImage.Price}}</span></p>
                </div>
                <div *ngIf="productImage.oldPrice" fxFlex="30" class="text-end m-t-10">
                  <p class="f-s-12 sale" color="white">Sale</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slider -->
      <div class="text-section">
        <div class="main-slider">
          <div class="swiper-container h-100" [swiper]="config">
            <div class="swiper-wrapper">
              <div *ngFor="let slide of slider" class="swiper-slide p-relative">
                <img [attr.data-src]="slide.image" class="slide-item swiper-lazy before-after-slides slider-img" />
                <div class="content p-abs top-0 c-white">
                  <div class="p-relative top-left">
                    <p class="m-t-12 f-s-36 f-w-700">{{slide.title}}</p>
                    <p class="m-t-20">{{slide.subtitle}}</p>
                    <button class="shop-btn product-button p-fixed" mat-raised-button color="black"
                      routerLink="/products/all">Shop the collection</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-pagination white"></div> -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
      <!-- this.allItems[2].Category -->
      <div class="p-30" *ngIf="shopPacksProduct.length > 0">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">{{allItems[2]?.Category}}</p>
          <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
            fxLayoutGap="20px" fxFlex="100">
            <div *ngFor="let productImage of shopPacksProduct; let i=index" fxLayout="column" fxFlex="32"
              fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i+10)"
                (mouseout)="mouseOut(i+10)" fxLayout="column" fxFill class="p-relative cursor-pointer" id="parent">
                <img (click)="goToShop(productImage.ItemID)" [src]="env.imageUrl +productImage.ImageUrl" alt=""
                  class="img-bg" [id]="'product'+(i+10)" style="width: 100% !important;">
                <!-- <img [src]="productImage.url" alt="" class="img-bg d-none" [id]="'products'+i" style="width: 100% !important;"> -->
                <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                  <div class="p-absolute abs-width">
                    <button [id]="'btn'+(i+10)" class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                      mat-raised-button color="black" (click)="handleProduct(productImage)">Quick Add</button>
                  </div>
                </div>
              </div>
              <div fxFlex="10" fxLayout="row" class="content m-t-20">
                <div fxFlex="70">
                  <p><span class="font-IBM">{{productImage.ProductName}}</span></p>
                  <p><span class="m-t-10 font-IBM"><del *ngIf="productImage.oldPrice">{{productImage.oldPrice}} </del>
                      {{'$'+productImage.Price}}</span></p>
                </div>
                <div *ngIf="productImage.oldPrice" fxFlex="30" class="text-end m-t-10">
                  <p class="f-s-12 sale" color="white">Sale</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- the new collection -->
      <div class="m-t-20">
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="50" class="bg-img p-relative">
            <img src="https://cdn.shopify.com/s/files/1/0926/9298/files/Banner_03.jpg?v=1600973446&width=1512"
              alt="image">
            <div class="content p-abs c-white">
              <div class="p-relative p-content-div">
                <p><span class="f-s-12">INTRODUCING</span></p>
                <p class="m-t-12 f-s-36 f-w-700">The New Collection</p>
                <p class="m-t-20 w-35">Sustainable activewear inspired by the real lives and real bodies of our
                  customers.</p>
                <p class="m-t-30 shop-btn" routerLink="/products/all"><a class="c-black f-s-14">Shop now</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin: 40px 28px 0px 28px; border-color:var(--theme-default-grey)">
      <!-- social -->
      <div class="p-30">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">Social</p>
          <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
            fxLayoutGap="20px" fxFlex="100">
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class=" text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/files/BTN01_2x_234e83a8-4a87-4dac-a89b-1c7eb62a2f9e_540x.png?v=1600987056"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-14 f-w-400 m-t-10">We've just launched our newest pair of seamless tights. Pictured here and
                in stores soon.</p>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class=" text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/files/BTN03_2x_b8612da0-9555-44d0-a972-fa1fa9c62f77_540x.png?v=1600987024"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-14 f-w-400 m-t-10">Our newest line of tights come in navy and black and are sure to get you
                running.</p>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <div class=" text-center">
                <img
                  src="https://cdn.shopify.com/s/files/1/0926/9298/files/FLEXTANK_WHITE_2.jpg?crop=center&height=300&v=1600985712&width=300"
                  alt="Leggings" width="100%">
              </div>
              <p class="f-s-14 f-w-400 m-t-10">Celebrating Mondays that get us moving - from the track to the pool.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>



<!-- 

    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    
    
    
                               NEW CODE
    
    
    
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************
    ************************************************************

 -->


<ng-template>


<ng-container>
  <div id="HomePageCustom">

    <!-- slideshow -->
    <div class="container slideshow">
      <div class="video-container">
        <!-- Desktop -->
        <video fxShow="true" fxShow.xs="false" fxShow.sm="false" autoplay [muted]="true" loop playsinline id="myVideo">
          <source src="https://cdn.shopify.com/videos/c/o/v/6cbfea6a989d49f1b136c03500f67e25.mp4" type="video/mp4">
        </video>
        <!-- Tablet -->
        <video fxShow="false" fxShow.xs="false" fxShow.sm="true" autoplay [muted]="true" loop playsinline id="myVideo">
          <source src="https://cdn.shopify.com/videos/c/o/v/3625090a31404f0499bcd6cfacdf1c9a.mp4" type="video/mp4">
        </video>
        <!-- Mobile -->
        <video fxShow="false" fxShow.xs="true" fxShow.sm="false" autoplay [muted]="true" loop playsinline id="myVideo">
          <source src="https://cdn.shopify.com/videos/c/o/v/f2c0e2483c514f0ebc70f8ece9a6478a.mp4" type="video/mp4">
        </video>
        <!-- <div class="content c-white xs-content">
          <p class="m-t-12 f-s-36 f-w-700 title-text text-center xs-font-size-25">Put you health</p>
          <p class="m-t-12 m-b-30 f-s-36 f-w-700 title-text text-center xs-font-size-25">Into your own hands</p>
        </div> -->
      </div>
    </div>

    <!-- Bundles -->

    <section class="container bundles-section m-t-20">
      <div class="title-section" fxLayoutGap="30px" fxLayoutAlign="row nowrap" fxLayoutAlign="start center">
        <span class="w-100"></span>
        <h3>BUNDLES</h3>
        <span class="w-100"></span>
      </div>
      <div class="products" fxLayout="row wrap" fxLayoutGap="20px">
        <div class="swiper-container h-100 p-b-30" [swiper]="BundlesConfig">
          <div class="swiper-wrapper p-b-20 m-b-20">
            <div class="swiper-slide p-relative" *ngFor="let product of AvailableBundles">
              <img [src]="product.imageUrl" alt="" class="slide-item swiper-lazy before-after-slides slider-img"
                (click)="goToItem(product.itemID, 'pack')">
              <div class="title" [textContent]="product.Description"></div>
              <div fxLayoutAlign="space-evenly center" class="w-100">
                <button class="shop-btn-global m-t-15 p-l-20 p-r-20 p-t-0 p-b-0 h-35" (click)="addPackToCart(product)">ADD</button>
                <button class="shop-btn-global m-t-15 p-l-20 p-r-20 p-t-0 p-b-0 h-35" (click)="goToItem(product.itemID, 'pack')">Details</button>
              </div>
            </div>
          </div>
          <div class="swiper-pagination white"></div>
          <!-- <div class="swiper-button-prev"></div> -->
          <!-- <div class="swiper-button-next"></div> -->
        </div>
      </div>
    </section>

    <!-- ShOP AO Scan -->

    <section class="container image-box nano-technology m-b-30">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <!-- left image -->
        <div fxFlex="40" class="left" fxLayoutAlign="center stretch">
          <img class="img-fluid" src="../../../../assets/images/otherImags/Future is Frequency.webp" alt="">
        </div>

        <!-- right content -->
        <div fxFlex="60" class="right" fxLayoutAlign="start center">
          <div fxFlex=100 fxFlex.gt-md="70" class="color-blackish" fxLayout="column" fxLayoutGap="18px"
            fxLayoutAlign="center">
            <h3 class="color-blackish f-w-400">THE FUTURE IS FREQUENCY</h3>
            <p class="f-s-18 f-w-300">AO Scan Technology is changing the way people approach health.  Learn how your body is performing from any device, no matter where you are.</p>
            <button class="shop-btn-global p-15 p-l-30 p-r-30 bg-white" [routerLink]="'/products/all'" [queryParams]="{ searchtext: 'ao scan' }">Shop AO Scan</button>
          </div>
        </div>
      </div>
    </section>

    <!-- Subscribe and Reward -->
    <div class="container m-t-30 m-b-30">
      <div class="bg-white-smoke p-t-20 p-b-20 p-15 m-t-30 m-b-30" id="Subscribe-And-Reward">
        <div class="container text-uppercase color-blackish" fxLayout="column"  fxLayout.gt-sm="row" fxLayoutGap="20px">
          <div fxFlex="100" fxFlex.gt-md="80" class="">
            <h3 class="f-s-22 color-blackish">Subscribe and Be rewarded</h3>
            <span class="f-s-18 text-italic">Learn how to maximize your purchases</span>
          </div>
          <div fxFlex="100" fxFlex.gt-md="20" fxLayoutAlign="start end">
            <button class="text-uppercase" (click)="openLoyaltyPopup()">Learn More</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Categories Card -->
    <div class="m-t-30 m-b-30 container">
      <div fxLayout="row column" id="Categories-Cards" fxLayoutAlign.gt-sm="start">
        <ng-container *ngIf="productService.retailStoreCategories.length > 0" >
          <ng-container *ngFor="let category of productService.retailStoreCategories">
            <div fxFlex="100" fxFlex.gt-sm="32" *ngIf="getImageUrlFromDescription(category.CategoryTranslations)" class="card"
              [style.background-image]="'url(' + getImageUrlFromDescription(category.CategoryTranslations) + ')'"  [style.background-position]="(category.ID == 27 ? 'center' : '')">
              <div class="content w-100" fxLayout="column" fxLayoutAlign="center center">
                <div class="name">{{category.ID == 21 ? "Get Started" : category.Name}}</div>
                <button class="m-t-10 shop-btn-global" [routerLink]="'/products/' + (category.ID == 21 ? 'all' : category.ID)">Shop</button>
              </div>
            </div>
  
            <!-- <div fxFlex="100" fxFlex.gt-sm="32" class="card"
              [style.background-image]="'url(' + category.ImageURL + ')'">
              <div class="content w-100" fxLayout="column" fxLayoutAlign="center center">
                <div class="name">{{category.Name}}</div>
                <button class="m-t-10 shop-btn-global">Shop</button>
              </div>
            </div> -->
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- Experience Nano -->
    <div class="container">
      <div class="bg-white-smoke p-t-20 p-b-20 p-15 m-t-30 m-b-30" id="Subscribe-And-Reward">
        <div class="text-uppercase color-blackish" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="24px">
          <div fxFlex="100" fxFlex.gt-md="80" fxLayoutAlign="start" fxLayout="row" fxLayout.lt-md="column">
            <img src="../../../../assets/images/Nano Icon.png" alt="" width="100" class="img-fluid m-r-20">
            <div fxLayout="column" fxLayoutAlign="center start">
              <h3 class="color-blackish">Experience NanoTechnology</h3>
              <span class="text-italic">Allows for more effective and efficient absorption.</span>
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-md="20" fxLayoutAlign="start end">
            <button class="m-b-20 text-uppercase" [routerLink]="'/products/all'" [queryParams]="{ searchtext: 'nanotechnology' }">Shop</button>
          </div>
        </div>
      </div>
    </div>

    <!-- AO Infinity Rules -->
    <div class="container">
      <div class="image-box nano-technology m-t-30 m-b-30 ao-infinity-block">
        <div fxLayout="column" fxLayout.gt-sm="row-reverse">
          <!-- left image -->
          <div fxFlex="40" class="left" fxLayoutAlign="center stretch">
            <img class="img-fluid" src="../../../../assets/images/otherImags/AO Infinity Wand website photo.webp" alt="">
          </div>
  
          <!-- right content -->
          <div fxFlex="60" class="right" fxLayoutAlign="end center">
            <div fxFlex="100" fxFlex.sm="80" fxFlex.md="100" fxFlex.lg="70" class="color-blackish" fxLayout="column" fxLayoutGap="18px"
              fxLayoutAlign="center end">
              <h3 class="color-blackish f-w-400 f-s-26 text-right" style="text-transform: uppercase;"> 
                Take your health to the next level
                with the AO Infinity products </h3>
              <!-- <p class="f-s-18 text-right f-w-300">
               
              </p> -->
              <button class="shop-btn-global p-15 p-l-30 p-r-30 bg-white" [routerLink]="'/products/all'" [queryParams]="{ searchtext: 'ao infinity' }">Shop AO Infinity</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Not is Use -->
    <ng-template>
      
      <!-- Learn how to -->
      <div class="learn-how-to">
        <div class="title">Learn How to get rewarded after purchases</div>
      </div>

      <!-- NANO Technology -->

      <section class="container nano-technology">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <!-- left image -->
          <div fxFlex="35" class="left" >
            <img class="img-fluid" src="../../../../assets/images/lifestyle-images/DSC00532-Format.jpg" alt="">
          </div>

          <!-- nono image -->
          <div class="nono-image" fxHide fxShow.gt-sm="true">
            <img class="img-fluid" src="../../../../assets/images/Nano White.png" alt="">
          </div>

          <!-- right content -->
          <div fxFlex="65" class="right">
            <div class="content" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center">
              <h3>NANO COMES FROM A `NANOMETER' (1 BILLIONTH OF A METER). TO PUT IT IN PERSPECTIVE, TAKE A ROUND
                MARBLE THAT IS COMMONLY USED TO PLAY GAMES AND COMPARE THAT TO THE SIZE OF THE WORLD.</h3>
              <span>Nano Technology separates the binding of molecular structure into smaller and smaller sizes to
                allow for more effective and efficient absorption. When this happens with molecular structures
                we consume in our supplements, they become bioavailable at the cellular level.
              </span>
              <button class="btn-big" routerLink="/product/951">SHOP NANO</button>
            </div>
          </div>
        </div>
      </section>

      <!-- Prime Technology -->

      <section class="container prime-section m-t-20">
        <div fxLayout="column" fxLayout.gt-sm="row-reverse">
          <!-- left image -->
          <div fxFlex="35" class="left">
            <img class="img-fluid" src="../../../../assets/images/lifestyle-images/DSC00704-format.jpg" alt="">
          </div>

          <!-- right content -->
          <div fxFlex="65" class="right">
            <div class="content" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center">
              <h3>IT'S ABOUT PRIME</h3>
              <span>Give your body the nutritional supplementation it deserves! Prime optimizes cell function and
                life by activating the cell's natural defenses, restoring cell structure from environmental
                damage, and maximizing the longevity of cell life.
              </span>
              <button class="btn-big" [routerLink]="['/products/all']" [queryParams]="{ searchtext: 'prime' }">SHOP
                PRIME</button>
            </div>
          </div>
        </div>
      </section>

      <!-- AO Scan and Lightwave  -->

      <section class="container AO-Scan-Lightwave-Section m-b-30">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="30px">
          <div fxFlex="50" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
            <img src="../../../../assets/images/DSC00103-format.jpg" alt="" class="img-fluid">
            <div class="title-text">AO SCAN TECHNOLOGY</div>
            <button class="btn-big" routerLink="/join">SHOP AO SCAN</button>
          </div>
          <div fxFlex="50" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
            <img src="../../../../assets/images/DSC00338-format.jpg" alt="" class="img-fluid">
            <div class="title-text">Lightwave Glasses</div>
            <button class="btn-big" routerLink="/join">SHOP LIGHTWAVE GLASSES</button>
          </div>
        </div>
      </section>

    </ng-template>

  </div>
</ng-container>

</ng-template>

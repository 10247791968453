<!-- ---------------------------------------------------
      ----------------   DISPLAYED   --------------------
  --------------------------------------------------- -->

<div>

  <!-- Search Bar -->

  <div class="custom-product-search-container" fxLayout="row" >
    
    <button (click)="handleProductSearch()" fxFlex="6" fxFlex.lt-md="25" fxLayoutAlign="center center">
      <svg class="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
      </svg>
    </button>

    <input matInput name="searchText" (keyup)="handleProductSearch()" fxFlex.md="" fxFlex="78" fxFlex.lt-md="75"  appearence="none" [(ngModel)]="productFilters.searchText" placeholder="Search for Products..." />
    
    <div fxShow fxHide.lt-md fxLayoutAlign="center center" fxFlex="16" class="category-selector">
      <select (change)="handleProductSearch()" [(ngModel)]="productFilters.selectedCategory"  [value]="productFilters.selectedCategory" name="product_cat">
        <option [value]="'all'">All Categories</option>
        <option *ngFor="let option of itemsListService.categoryList" class="" [value]="option.CategoryId"> 
          {{ option.Category }}
        </option>
      </select>
    </div>

  </div>

  <builder-component 
  *ngIf="!BuilderConfigurations.noBuilderPageForUrl && BuilderConfigurations.data.ProductWrapperList.length > 0"
  (load)="BuilderConfigurations.noBuilderPageForUrl = $event ? false : true"
  (error)="BuilderConfigurations.noBuilderPageForUrl = true"
  model="shop-page-products-list" [data]="BuilderConfigurations.data"
    [options]="BuilderConfigurations.options" [context]="BuilderConfigurations.context">
  </builder-component>

    <div class="text-center m-30 p-20" *ngIf="BuilderConfigurations.data.ProductWrapperList.length === 0">
      No Products found!!
    </div>
</div>


<!-- ---------------------------------------------------
      ----------------   HIDDEN   --------------------
  --------------------------------------------------- -->

<ng-template>

  <div class="prouduct bgm-white" id="product-no-sidebar-component">
    <!-- <div class="p-30">
        <div fxLayout="column" class="bg-white-smoke" fxFill fxLayout.gt-sm="row" fxLayoutAlign="center center" fxFlex>
          <div *ngIf="selectedCategory.CategoryId=='10'" fxFlexOrder.lt-md="2" fxLayout="column" fxFlex="50"
            fxFlex.lt-md="100" fxLayoutAlign="center center" fxFill>
            <div class="content p-l-30 p-r-30" fxLayout="column" fxFlex="50" fxFlex.lt-md="100" fxLayoutAlign="center">
              <p class="m-t-12 f-s-24 f-w-700">Womens</p>
              <p class="m-t-20 f-s-14 w-72">Made from sustainable materials with labour standards we're proud of. Our small
                collection is inspired by years of working with athletes. Each piece is created to ensure performance.</p>
            </div>
          </div>
          <div *ngIf="selectedCategory.CategoryId=='10'" fxFlexOrder.lt-md="1" fxLayout="column" fxFlex="50"
            fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
            <img
              src="https://cdn.shopify.com/s/files/1/0926/9298/collections/HP_BANNER_DT_2000x_c65dfc3f-5b90-47f9-ad72-f4bf67bc6e8b_720x.jpg?v=1600989067"
              alt="product-image" width="100%">
          </div>
          <div *ngIf="selectedCategory.CategoryId=='11'" fxFlexOrder.lt-md="2" fxLayout="column" fxFlex="50"
            fxFlex.lt-md="100" fxLayoutAlign="start" fxFill>
            <div class="content p-l-30 p-r-30" fxLayout="column" fxFlex="50" fxLayoutAlign="start">
              <p class="m-t-12 f-s-24 f-w-700">Mens</p>
            </div>
          </div>
          <div *ngIf="selectedCategory.CategoryId=='11'" fxFlexOrder.lt-md="1" fxLayout="column" fxFlex="50"
            fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
            <img src="https://cdn.shopify.com/s/files/1/0926/9298/collections/LNDR_Mens_homepage_300x300.png?v=1601056370"
              alt="product-image" width="100%">
          </div>
        </div>
      </div> -->

    <!-- Save up to 20% section -->
    <!-- <div fxLayout="row">
        <div fxFlex="100" fxFlex.gt-md="65" fxLayoutGap="26px" class="save-discount-section p-15 m-t-30 m-b-30"
          fxLayout="column" fxLayoutAlign="center center">
          <div class="title"> Get a FREE Ticket to Day of Discovery with your enrollment today!* </div>
          <div class="sub-title" fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="100" fxFlex.gt-md="55" class="text-center">
              Virtual DoD is a pre-recorded online event packed with guest speakers, special discounts, business tips, new launches, information about your AO Scan, and MUCH MORE.  
            </div>
          </div>
          <span class="text-center f-s-18 color-blackish">March 6 - March 12</span>
          <div class="btn-group" fxLayout="row" fxLayoutGap="30px">
            <button [routerLink]="'/products/3'">Bundles</button>
            <button [routerLink]="'/products/all'" [queryParams]="{ searchtext: 'ao scan' }">Shop AO Scan</button>
          </div>
        </div>
      </div> -->

    <!-- DeskTop Swiper -->
    <div id="ShopBannerWrapper" class="swiper-container h-100" [swiper]="shopBannerConfig">
      <div class="swiper-wrapper">
        <div *ngFor="let slide of BannerSlider" class="swiper-slide p-relative">
          <div class="shades-image" [ngStyle]="{'background-image': 'url(' + slide.desktopImage + ')'}"
            [ngStyle.xs]="{'background-image': 'url(' + slide.mobileImage + ')'}"
            [ngStyle.sm]="{'background-image': 'url(' + slide.tabletImage + ')'}"
            [ngStyle.md]="{'background-image': 'url(' + slide.desktopImage + ')'}">
            <div class="layer">
              <!-- <div class="shades-image-inside-heading">START WITH <br> A BUNDLE</div> -->
              <div class="banner-btn-group">
                <div *ngIf="slide.button1.allowed" class="banner-btn" (click)="RedirectAllBundlesURL(slide.button1.id)">
                  {{ slide.button1.text }}
                </div>
                <div *ngIf="slide.button2.allowed" class="banner-btn m-t-10"
                  (click)="RedirectAllBundlesURL(slide.button2.id)">
                  {{ slide.button2.text }}
                </div>
                <div *ngIf="slide.button3.allowed" class="banner-btn m-t-10"
                  (click)="RedirectAllBundlesURL(slide.button3.id)">
                  {{ slide.button3.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination white"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

    <!-- actual products section -->

    <div class="">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start stretch" fxLayoutGap="20px">
        <div fxFlex="25" fxFlex.lt-sm="100" class="m-t w-100 " style="background-color: rgba(233,233,233,255) ;">
          <!-- start -->
          <div class="main" fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="80" fxLayout="column" class="firstContainer" fxLayoutAlign="center center">

              <!-- sort_by -->
              <div *ngIf="false" class="m-t-20">
                <div>
                  <span class="color1-f-s-17 f-s-20 color-black f-w-700 text-uppercase" translate="sort_by"></span>
                </div>
                <div class="m-t-20">
                  <form>
                    <div class="sortBy">
                      <mat-form-field appearance="outline" class="w-100 design">
                        <mat-select [(ngModel)]="sortByOrder" [compareWith]="compareObjects"
                          (selectionChange)="onChangeSorting($event)" [ngModelOptions]="{standalone: true}">
                          <mat-option *ngFor="let keys of sortKeys" [value]="keys"> {{keys.name | translate}} -
                            {{keys.lname
                            | translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </form>
                </div>
              </div>

              <!-- FILTER BY: -->
              <div *ngIf="false">
                <div class="m-t-20">
                  <span class="color1-f-s-17 f-s-20 color-black f-w-700">FILTER BY:</span>
                </div>
                <div class="m-t-20 borderBottom">
                  <form>
                    <div fxLayout="column" fxFlex="100" fxLayoutGap="10px">
                      <div class="checkBox" *ngFor="let option of filterByOptions">

                        <mat-checkbox [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="filterBySelectedValue[option.value]" color="warn"
                          class="color-black text-uppercase">
                          {{option.title}}
                        </mat-checkbox>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!-- by_category -->
              <div class="m-t-20 no-checkbox-container">
                <div class="p-t-20 w-100 borderTopLine">
                  <div class="color1-f-s-17 f-s-20 color-black text-uppercase text-center">Shop By Category</div>
                </div>
                <div class="m-t-20 borderBottom">
                  <div class="">
                    <form>
                      <div fxLayout="column" fxFlex="100" fxLayoutGap="10px" class="w-100">
                        <div (click)="checkBoxParentClicked($event, 'all')" class="checkBox"
                          [ngClass]="{'checkbox-selected': itemsListService.selectedCategories['all'] }">
                          <mat-checkbox [attr.checkbox-id]="'all'" id="all"
                            [(ngModel)]="itemsListService.selectedCategories['all']"
                            (change)="itemsListService.getItemsByCategory()" [ngModelOptions]="{standalone: true}"
                            class="color-black text-uppercase" color="warn">{{'shop_all_' | translate}}</mat-checkbox>
                        </div>
                        <div (click)="checkBoxParentClicked($event, category.CategoryId)"
                          *ngFor="let category of itemsListService.categoryList" class="checkBox"
                          [ngClass]="{ 'checkbox-selected': itemsListService.selectedCategories[category.CategoryId] }">
                          <mat-checkbox [attr.checkbox-id]="category.CategoryId"
                            [(ngModel)]="itemsListService.selectedCategories[category.CategoryId]"
                            [attr.id]="category.CategoryId"
                            (change)="onChange(itemsListService.selectedCategories, category.CategoryId)"
                            [ngModelOptions]="{standalone: true}" color="warn"
                            class="color-black text-uppercase">{{category.Category}}
                          </mat-checkbox>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <!-- -- -- -- -- -- -- -- 
                  Popular Products
                -- -- -- -- -- -- -- -->

              <div class="m-t-30" *ngIf="itemsListService.productList?.length > 0">
                <div class="m-t-30">
                  <div class="p-t-20 w-100 borderTopLine m-t-30">
                    <div class="color1-f-s-17 f-s-20 color-black text-uppercase text-center">Popular Products</div>
                  </div>
                  <div fxLayout="column" fxLayoutGap="20px">
                    <ng-container *ngFor="let product of productService.allItems; let i=index">
                      <div fxFlex="100" *ngIf="product.ItemID == 748 || product.ItemID == 519">
                        <img (click)="goToShop(product.ItemID)" [src]="env?.imageUrl +product?.ImageUrl" alt=""
                          class="img-bg cursor-pointer " [id]="'product'+i" style="width: 100% !important;">
                        <div fxLayout="row" class="content m-t-20" fxLayoutAlign="center">
                          <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="14px">

                            <!-- #5963 NEW DESIGN -->

                            <div id="Item-Quick-Details" fxLayout="column" fxLayoutGap="16px"
                              fxLayoutAlign="center center" class="w-100">
                              <hr style="border: 1px solid #000000; width: 80%;" />
                              <span class="font-IBM text-center f-s-24 color-blackish">{{product.ProductName}}</span>

                              <!-- Pricing -->
                              <span *ngIf="product.Price" class="f-s-18 f-w-500 color-blackish">{{ product?.Price |
                                currency: companyService.selectedCurrency.CurrencyCode: 'symbol':'1.2-2' }}</span>
                              <span *ngIf="!product.Price" class="f-s-18 f-w-500 color-blackish">N/A</span>

                              <!-- Buttons -->
                              <div fxLayoutAlign="space-between center" class="w-100 m-t-20">
                                <div class="shopping-btn bg-white" (click)="addToCart(product)">Add</div>
                                <div class="shopping-btn bg-white" (click)="goToShop(product.ItemID)">Details</div>
                              </div>
                            </div>

                            <!-- #5963 NEW DESIGN -->

                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div fxFlex="75" id="ProductsListSection">

          <!-- Search Bar -->
          <div class="m-t-10 m-b-30">
            <form>
              <div fxLayout="row" class="m-r-10 m-l-10" fxLayoutAlign="start center">
                <div fxFlex="97" fxFlex.lt-sm="100" class="searchBox">
                  <mat-form-field fxFlex appearance="outline" class="design">
                    <input name="search" [(ngModel)]="productSearchText" [ngModelOptions]="{standalone: true}" matInput
                      placeholder="Search Products">
                    <mat-icon class="m-t-10" matSuffix>search</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>

          <!-- Products -->
          <div [ngClass]="{'m-t--4rem': (itemsListService.productList?.length > 0)}" class="m-t-10 height-fit-content"
            fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap.gt-md="27px">
            <div
              *ngFor="let product of itemsListService.productList | productSearch: productSearchText : filterBySelectedValue  | productOrderBy: sortByOrder; let i=index"
              fxLayout="column" fxLayoutGap="20px" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100"
              fxLayoutAlign="center" fxFill class="m-t-30 p-10">
              <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i)"
                (mouseout)="mouseOut(i)" fxLayout="column" fxFill class="p-relative">
                <img (click)="goToShop(product.ItemID)" [src]="env?.imageUrl +product?.ImageUrl" alt=""
                  class="img-bg cursor-pointer" [id]="'product'+i" style="width: 100% !important;">
                <!-- <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                    <div id="div" class="p-absolute abs-width">
                      <button [id]="'btn'+i" class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                        mat-raised-button color="black" (click)="handleProduct(product)">Quick Add</button>
                    </div>
                  </div> -->
              </div>
              <div fxFlex="10" fxLayout="row" class="content m-t-20" fxLayoutAlign="center">
                <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="14px">


                  <ng-template>
                    <span
                      class="font-IBM text-uppercase text-center f-s-18 f-w-700 color-black">{{product.ProductName}}</span>
                    <!-- <span class="text-center">{{product. }}</span> -->
                    <span class="text-uppercase text-center f-w-700 m-b-5 from-text">from</span>
                    <span class="m-t-10 font-IBM text-uppercase f-s-15 f-w-700 text-center"><del
                        *ngIf="product.oldPrice">{{product.oldPrice}} </del>
                      {{'$'+ (product.Price | number: '1.2-2') }}</span>
                  </ng-template>


                  <!-- #5963 NEW DESIGN -->

                  <div id="Item-Quick-Details" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center"
                    class="w-100">
                    <hr style="border: 1px solid #000000; width: 80%;" />
                    <span class="font-IBM text-center f-s-24 color-blackish">{{product.ProductName}}</span>

                    <!-- Pricing -->
                    <span *ngIf="product.Price" class="f-s-18 f-w-500 color-blackish">{{ product?.Price | currency:
                      companyService.selectedCurrency.CurrencyCode: 'symbol':'1.2-2' }}</span>
                    <span *ngIf="!product.Price" class="f-s-18 f-w-500 color-blackish">N/A</span>

                    <!-- Buttons -->
                    <div fxLayoutAlign="space-between center" class="w-100 m-t-20">
                      <div class="shopping-btn" (click)="addToCart(product)">Add</div>
                      <div class="shopping-btn" (click)="goToShop(product.ItemID)">Details</div>
                    </div>
                  </div>

                  <!-- #5963 NEW DESIGN -->


                </div>
                <div *ngIf="product.oldPrice" fxFlex="30" class="text-end m-t-10">
                  <p class="f-s-12 sale" color="white">Sale</p>
                </div>
              </div>
            </div>
            <div class="w-100 text-center m-50" *ngIf="!isProductsFetched">
              Loading Products...
            </div>
            <div class="w-100 text-center m-50"
              *ngIf="(((itemsListService.productList |  productSearch:productSearchText:filterBySelectedValue)?.length===0) && isProductsFetched)">
              No Products found!!
            </div>
            <!-- <div class="flex flex-auto flex-col items-center w-100 justify-center bg-gray-100 dark:bg-transparent"
                *ngIf="(((itemsListService.productList |  productSearch:productSearchText:filterBySelectedValue)?.length===0))">
                <mat-icon class="icon-size-24" [svgIcon]="'iconsmind:file_hide'"></mat-icon>
                <div class="mt-4 text-2xl text-center font-semibold tracking-tight text-secondary">There is no data available!</div>
              </div> -->
          </div>

        </div>
      </div>
    </div>
    <!-- <hr style="margin: 40px 28px 0px 28px; border-color:var(--theme-default-grey)"> -->


    <!-- ---------------------------------------------------
        ---------------------------------------------------
          ----------------   HIDDEN   --------------------
        ---------------------------------------------------
      --------------------------------------------------- -->


    <ng-template>
      <!-- Featured collection -->
      <div class="p-30">
        <div fxLayout="column">
          <p class="f-s-24 f-w-700">Featured collection</p>
          <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
            <div *ngFor="let product of products; let i=index" fxLayout="column" fxFlex="32" fxFlex.lt-md="100"
              fxLayoutAlign="center" fxFill class="m-t-30 p-10">
              <div fxFlex="70" fxFlex.lt-md="100" fxLayoutAlign="center center" (mouseover)="mouseEnter(i+1000)"
                (mouseout)="mouseOut(i+1000)" fxLayout="column" fxFill class="p-relative">
                <img [ngStyle.lt-sm]="{'height.px': 380}" (click)="goToShop(product.ItemID)"
                  [src]="env?.imageUrl +product?.ImageUrl" alt="" class="img-bg cursor-pointer"
                  [id]="'product'+(i+1000)" style="width: 100% !important;">
                <!-- <div class="content" fxLayout="column" fxFill fxLayoutAlign="end">
                    <div class="p-absolute abs-width">
                      <button [id]="'btn'+(i+1000)" class="shop-btn product-button d-none w-89 m-t-20 m-b-20 m-r-0 m-l-0"
                        mat-raised-button color="black" (click)="handleProduct(product)">Quick Add</button>
                    </div>
                  </div> -->
              </div>
              <div fxFlex="10" fxLayout="row" class="content m-t-20">
                <div fxFlex="70">
                  <p><span class="font-IBM">{{product.ProductName}}</span></p>
                  <p><span class="m-t-10 font-IBM"><del *ngIf="product.oldPrice">{{product.oldPrice}} </del>
                      {{'$'+product.Price}}</span></p>
                </div>
                <div *ngIf="product.oldPrice" fxFlex="30" class="text-end m-t-10">
                  <p class="f-s-12 sale" color="white">Sale</p>
                </div>
              </div>
            </div>
            <div class="w-100 text-center m-30 p-20" *ngIf="products.length === 0">
              No Products found!!
            </div>
          </div>
        </div>
      </div>
      <!-- Best Seller -->
      <div class="p-30">
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="center" fxFlex="50" class="bg-img p-relative">
            <img src="https://cdn.shopify.com/s/files/1/0926/9298/files/Banner_03.jpg?v=1600973446&width=1512"
              alt="image">
            <div class="content p-abs c-white">
              <div class="p-relative p-content-div text-center">
                <p><span class="f-s-12">
                    FAVOURITES
                  </span></p>
                <p class="m-t-12 f-s-36 f-w-700">Best Sellers</p>
                <p class="m-t-20">Shop our best selling collection - everything from the perfect pair of tights, to
                  totally
                  matching sweat suits.</p>
                <p class="m-t-30 shop-btn"><a class="c-black f-s-14" routerLink="/products/all">Shop now</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>

</ng-template>

<builder-component
  model="footer"
  [options]="options"
></builder-component>


<ng-template>

  <footer id="footer" class="footer sec-padding">
    <!-- <div class="container">
        <div class="footer-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
          <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="50" class="footer-widget about-widget">
            <a class="logo" routerLink="/home"><img [src]="configService.localSettings.Company.Images.footerlogo" alt="Awesome Image"></a>
            <p><span class="c-black logo cursor-pointer" routerLink="/home">{{'Clientname' | translate}}</span></p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada fringilla sem, at dictum lectus ultricies quis. Etiam eu bibendum orci. Aliquam erat volutpat.</p>
            <div class="link f-s-14 a-hover"><a routerLink="/about">MORE ABOUT US</a> </div>
          </div>
  
            <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2" class="footer-widget links-widget links-widget-pac">
              <div class="title">
                <h5 class="color-blackish">Links</h5>
              </div>
              <div class="row">
                  <ul>
                    <li><a  routerLink="/home">Home</a></li>
                    <li><a  href="javascript: vouf" target="_blank">Refund Policy</a></li>
                    <li><a  href="../../../../assets/pdfs/Return & Shipping Policies.pdf" target="_blank">Shipping and Return Policy</a></li>
                    <li><a  href="../../../../assets/pdfs/Terms & Conditions.pdf" target="_blank">Terms and Conditions</a></li>
                    <li><a  href="../../../../assets/pdfs/Policies & Procedures.pdf" target="_blank">Policies and Procedures</a></li>
                    <li><a routerLink="/products/all">Shop All</a></li>
                    <li><a  routerLink="/faq">Faq</a></li>
                    <li *ngIf="!isLoggedIn()"><a  routerLink="/login">Sign In</a></li>
                    <li *ngIf="isLoggedIn()"><a (click)="logoutHandler()">Sign Out</a></li>
                    <li><a  routerLink="/contact">Contact</a></li>
                  </ul>
              </div>
            </div>
  
  
          <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2" class="footer-widget subscribe-widget">
            <div class="title">
              <h5 class="color-blackish">Subscribe Newsletter</h5>
            </div>
            <form action="#">
              <p>Get latest updates and offers.</p>
              <div fxLayout="column" class="newsletter-widget">
                <input type="text" class="b-r-23" placeholder="Enter your email address">
                <button mat-raised-button color="primary" type="submit" class="ped-btn-footer bgm-black b-r-23">Subscribe</button>
              </div>
            </form>
          </div>
  
  
            <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2" class="footer-widget subscribe-widget">
              <div class="title">
                <h5 class="color-blackish">Contact Us</h5>
              </div>
              <div fxLayout="row" class="tel-box">
                <div class="icon"><i class="fa fa-map-marker"></i></div>
                <div class="text">
                  <p>{{configService.localSettings.Company.Address.Street }} {{configService.localSettings.Company.Address.City}} {{configService.localSettings.Company.Address.State}} {{configService.localSettings.Company.Address.Address1}} {{configService.localSettings.Company.Address.Country}}</p>
                </div>
              </div>
              <div fxLayout="row" class="tel-box">
                <div class="icon"><i class="fa fa-phone"></i></div>
                <div class="text">
                  <p>{{configService.localSettings.Company.Address.Phone}}</p>
                </div>
              </div>
              <div fxLayout="row" class="tel-box">
                <div class="icon"><i class="fa fa-envelope"></i></div>
                <div class="text">
                  <p><a >{{configService.localSettings.Company.Address.Email}}</a></p>
                </div>
              </div>
  
              <ul fxLayout="row" class="social list-inline">
                <li *ngFor="let sociallink of configService.localSettings.Company.SocialLinks"><a [href]="sociallink.link" target="_blank"><i [class]="sociallink.icon"></i></a></li>
              </ul>
            </div>
        </div>
    </div> -->
    <div class="container">
      <div class="footer-wrapper" fxLayout="row wrap" fxLayoutAlign="space-around start">
        <div fxFlex="100" fxFlex.gt-sm="15" fxFlex.sm="50" class="footer-widget about-widget"
        fxLayoutAlign="start center" fxLayoutAlign.gt-md="center center">
          <a class="" routerLink="/home" fxFlex="40" fxFlex.gt-sm="50" fxFlex.gt-md="65"><img class="img-fluid" src="../../../../assets/images/5starlogo-horizontal.png"
              alt="Awesome Image"></a>
        </div>
        <!-- <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2" class="footer-widget links-widget links-widget-pac">
          <div class="title">
            <h4 class="color-blackish f-w-500">Company</h4>
          </div>
          <div class="row">
            <p><span class="c-black Clientname cursor-pointer" routerLink="/home">{{'Clientname' | translate}}</span></p>
            <p>5 Star products improve the lives of those who use them through state-of-the-art frequency technology.</p>
            <div class="link f-s-14 a-hover"><a routerLink="/about">MORE ABOUT US</a> </div>
          </div>
        </div> -->
        <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2"
          class="footer-widget links-widget links-widget-pac">
          <div class="title">
            <h4 class="color-blackish f-w-500">Links</h4>
          </div>
          <div class="row f-w-500">
            <ul>
              <li><a routerLink="/home">Home</a></li>
              <li><a href="../../../../assets/pdfs/Return & Shipping Policies.pdf" target="_blank">Shipping and Return
                  Policy</a></li>
              <li><a href="../../../../assets/pdfs/Terms & Conditions.pdf" target="_blank">Terms and Conditions</a></li>
              <li><a href="../../../../assets/pdfs/Policies & Procedures.pdf" target="_blank">Policies and Procedures</a>
              </li>
              <!-- <li><a routerLink="/products/all">Shop All</a></li>
                <li><a  routerLink="/faq">Faq</a></li>
                <li *ngIf="!isLoggedIn()"><a  routerLink="/login">Sign In</a></li>
                <li *ngIf="isLoggedIn()"><a (click)="logoutHandler()">Sign Out</a></li>
                <li><a  routerLink="/contact">Contact</a></li> -->
            </ul>
          </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"
          class="footer-widget subscribe-widget f-w-500">
          <div class="title">
            <h4 class="color-blackish f-w-500">Contact</h4>
          </div>
          <div fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-phone"></i></div>
            <div class="text">
              <p>{{configService.localSettings.Company.Address.Phone}}</p>
            </div>
          </div>
          <div fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-envelope"></i></div>
            <div class="text">
              <p><a>{{configService.localSettings.Company.Address.Email}}</a></p>
            </div>
          </div>
          <div fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-map-marker"></i></div>
            <div class="text">
              <p>{{configService.localSettings.Company.Address.Street }}
                {{configService.localSettings.Company.Address.City}} {{configService.localSettings.Company.Address.State}}
                {{configService.localSettings.Company.Address.Address1}}
                {{configService.localSettings.Company.Address.Country}}</p>
            </div>
          </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"
          class="footer-widget subscribe-widget f-w-500" fxLayout="column">
  
          <div class="title">
            <h4 class="color-blackish f-w-500 m-b-15">CONNECT</h4>
          </div>
  
          <!-- Social Icons -->
          <div fxLayout="row">
            <div fxFlex="60" fxFlex.gt-md="55" fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
              <div fxLayoutAlign="center center" class="social-icon" *ngFor="let sociallink of configService.localSettings.Company.SocialLinks">
                <a [href]="sociallink.link" target="_blank">
                  <i [class]="sociallink.icon"></i>
                </a>
              </div>
            </div>
          </div>
  
  
  
        </div>
      </div>
    </div>
  </footer>

</ng-template>

<mat-sidenav-container fullscreen>
  <mat-sidenav [opened]="false" mode="over" #start class="sidenav" *ngIf="!utilityService.isShowSimplifiedheader">
    <div class="d-flex header p-5" fxLayout="row">
      <div class="move-left avtar" fxFlex="10" fxFlex.xs="15" fxLayoutAlign="start center">
        <img class="avtar" [default]="'assets/img/user.png'"
          [src]="userServiceModal.customerData.ImageUrl || userServiceModal.enrollerInfo.ImageUrl" />
      </div>
      <div class="ds_userName" fxFlex="70" fxFlex.sm="35" fxLayoutAlign="start center">
        <h2
          [textContent]="((userServiceModal.customerData.FirstName || userServiceModal.customerData.LastName)? welcmtext  :'') + ' ' + (userServiceModal.customerData.FirstName || userServiceModal.enrollerInfo.FirstName) + ' ' + (userServiceModal.customerData.LastName || userServiceModal.enrollerInfo.LastName)"
          class="f-s-22 text-uppercase"></h2>
      </div>
      <div fxLayoutAlign="end center" fxFlex="15" fxFlex.sm="55" fxLayoutAlign="end center">
        <button mat-icon-button color="warn" class="close" (click)="start.close()">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="sidebar-items" fxLayout="column" class="p-16">
      <app-sidebar *ngFor="let item of navItems" [item]="item" (click)="close(item)"
        (close)="closed(val)"></app-sidebar>
        <!-- Order Hitory -->
        <a *ngIf="accountService.isLoggedIn()" fxLayoutAlign="space-between flex-start" mat-list-item class="main-nav__link" routerLink="/orderhistory"
        [ngStyle]="{'padding-left': (depth * 12) + 'px'}" class="menu-list-item outside-link">
        <div fxFlex>
          <span [ngClass]="{'text-gray': '/orderhistory' ? router.isActive('/orderhistory', true): false}" class="v-align-middle m-10 side-list-title font-IBM">Order History</span>
        </div>
        </a>

        <!-- Manage Autoship -->
        <a *ngIf="accountService.isLoggedIn()" fxLayoutAlign="space-between flex-start" mat-list-item class="main-nav__link" routerLink="/manageautoship"
        [ngStyle]="{'padding-left': (depth * 12) + 'px'}" class="menu-list-item outside-link">
        <div fxFlex>
          <span [ngClass]="{'text-gray': '/   manageautoship' ? router.isActive('/manageautoship', true): false}" class="v-align-middle m-10 side-list-title font-IBM">Manage Autoship</span>
        </div>
        </a>
    </div>


    <!-- Country Selector -->
    <div class="widget-text m-l-10 m-t--10" (click)="close({})" [matMenuTriggerFor]="sidenavLanguageSelector">
      <a class="flag-icon" mat-button>
        <img 
          [src]="'https://directscalestring.directscale.com/flags/4x3/'+ configService.commonData.selectedCountry?.toLowerCase() +'.svg'"
          width="18">
        <span fxShow="false" fxShow.gt-sm class="flag-menu-title c-white">{{configService.commonData.selectedCountry
          | uppercase}} | {{configService.commonData.selectedLanguage | uppercase}}</span>
        <mat-icon class="mat-icon-sm caret cur-icon c-white">arrow_drop_down</mat-icon>
      </a>
    </div>

    <mat-menu #sidenavLanguageSelector="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
      <span *ngFor="let country of allowedCountries">
        <button mat-menu-item (click)="updatecountry(country, lng.LanguageCode)"
          *ngFor="let lng of country.CountryLanguages">
          <img [src]="'https://directscalestring.directscale.com/flags/4x3/'+country.CountryCode+'.svg'"
            width="18"> {{country.CountryName}} | {{lng.LanguageName}}
        </button>
      </span>
    </mat-menu>

  </mat-sidenav>
  <!-- topbar start -->

  <!-- For Desktop -->

  <div fxShow fxHide.lt-md>
    <ng-template>
      <mat-toolbar  class="bgm-black" *ngIf="!utilityService.isShowSimplifiedheader && showTopHeader">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="top-bar p-5">
          <div fxLayout="row" class="welcome-message" mat-button [matMenuTriggerFor]="enrollMenu">
            <div class="widget-text">
              <!-- Enroller Picture -->
              <div class="enroll_img" fxLayout="row" fxLayoutAlign="center center">
                <div>
                  <a class="avtar">
                    <img style="height:45px;width:45px;" [src]="userServiceModal.enrollerInfo.ImageUrl" alt="companylogo"
                      [default]="'assets/images/user.png'">
                  </a>
                </div>
                <div>
                  <button mat-button class="p-5 c-white">
                    <div class="shop_heading text-nonecase edb-space" translate="you_r_shop_with">
                    </div>
                    <span *ngIf="true">
                      <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500"
                        data-bind="label">{{userServiceModal.enrollerInfo.FirstName}}
                        &nbsp;{{userServiceModal.enrollerInfo.LastName}}</span>
                      <mat-icon>arrow_drop_down</mat-icon>
                    </span>
                  </button>
                </div>
              </div>
              <mat-menu #enrollMenu="matMenu">
                <button *ngIf="!isLoggedIn()" mat-menu-item
                  (click)="navigateToChangeAffiliate();">{{'market_change_affiliate'| translate}}
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="widgets-left" fxLayout="row">
            <div class="widget-text" [matMenuTriggerFor]="menu" *ngIf="!isLoggedIn()">
              <button mat-button class="c-white">
                <div class="shop_heading text-nonecase edb-space" translate="you_r_shopping_as">
                </div>
                <span class="text-uppercase f-500 text-truncate customer_type" [ngStyle.lt-sm]="{'font-size.px': 12}"
                  data-bind="label">{{customerTypeDescription()}}</span>
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngFor="let customerType of configService.commonSettings.CustomerTypes" mat-menu-item
                  (click)="changeCustomerType(customerType.ID)">{{customerType.Description | translate}}</button>
              </mat-menu>
            </div>
            <div class="vl"></div>
            <div class="widget-text m-t-10" [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
              <a class="flag-icon" mat-button>
                <img
                  [src]="'https://directscalestring.directscale.com/flags/4x3/'+ configService.commonData.selectedCountry?.toLowerCase() +'.svg'"
                  width="18">
                <span fxShow="false" fxShow.gt-sm class="flag-menu-title c-white">{{configService.commonData.selectedCountry
                  | uppercase}} | {{configService.commonData.selectedLanguage | uppercase}}</span>
                <mat-icon class="mat-icon-sm caret cur-icon c-white">arrow_drop_down</mat-icon>
              </a>
              <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
                <span *ngFor="let country of allowedCountries">
                  <button mat-menu-item (click)="updatecountry(country, lng.LanguageCode)"
                    *ngFor="let lng of country.CountryLanguages">
                    <img [src]="'https://directscalestring.directscale.com/flags/4x3/'+country.CountryCode+'.svg'"
                      width="18"> {{country.CountryName}} | {{lng.LanguageName}}
                  </button>
                </span>
              </mat-menu>
            </div>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-template>
    <!-- <div class="hidden-element" (mouseenter)="handleTopHeader('show')"></div> -->
    <app-header (mouseleave)="handleTopHeader('hide')" (sildeMobileNavEvent)="sildeMobileNav($event)"
      *ngIf="!utilityService.isShowSimplifiedheader">
    </app-header>
  </div>



  <!-- For Mobile -->

  <div fxHide fxShow.lt-md>

      <!-- Hidden Div | Below -->
      <ng-template>
        <mat-toolbar  class="bgm-black" *ngIf="!utilityService.isShowSimplifiedheader">
          <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="top-bar p-5">
            <div fxLayout="row" class="welcome-message" mat-button [matMenuTriggerFor]="enrollMenu">
              <div class="widget-text">
                <!-- Enroller Picture -->
                <div class="enroll_img" fxLayout="row" fxLayoutAlign="center center">
                  <div>
                    <a class="avtar">
                      <img style="height:45px;width:45px;" [src]="userServiceModal.enrollerInfo.ImageUrl" alt="companylogo"
                        [default]="'assets/images/user.png'">
                    </a>
                  </div>
                  <div>
                    <button mat-button class="p-5 c-white">
                      <div class="shop_heading text-nonecase edb-space" translate="you_r_shop_with">
                      </div>
                      <span *ngIf="true">
                        <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500"
                          data-bind="label">{{userServiceModal.enrollerInfo.FirstName}}
                          &nbsp;{{userServiceModal.enrollerInfo.LastName}}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                      </span>
                    </button>
                  </div>
                </div>
                <mat-menu #enrollMenu="matMenu">
                  <button *ngIf="!isLoggedIn()" mat-menu-item
                    (click)="navigateToChangeAffiliate();">{{'market_change_affiliate'| translate}}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="widgets-left" fxLayout="row">
              <div class="widget-text" [matMenuTriggerFor]="menu" *ngIf="!isLoggedIn()">
                <button mat-button class="c-white">
                  <div class="shop_heading text-nonecase edb-space" translate="you_r_shopping_as">
                  </div>
                  <span class="text-uppercase f-500 text-truncate customer_type" [ngStyle.lt-sm]="{'font-size.px': 12}"
                    data-bind="label">{{customerTypeDescription()}}</span>
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button *ngFor="let customerType of configService.commonSettings.CustomerTypes" mat-menu-item
                    (click)="changeCustomerType(customerType.ID)">{{customerType.Description | translate}}</button>
                </mat-menu>
              </div>
              <div class="vl"></div>
              <div class="widget-text m-t-10" [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                <a class="flag-icon" mat-button>
                  <img
                    [src]="'https://directscalestring.directscale.com/flags/4x3/'+ configService.commonData.selectedCountry?.toLowerCase() +'.svg'"
                    width="18">
                  <span fxShow="false" fxShow.gt-sm class="flag-menu-title c-white">{{configService.commonData.selectedCountry
                    | uppercase}} | {{configService.commonData.selectedLanguage | uppercase}}</span>
                  <mat-icon class="mat-icon-sm caret cur-icon c-white">arrow_drop_down</mat-icon>
                </a>
                <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
                  <span *ngFor="let country of allowedCountries">
                    <button mat-menu-item (click)="updatecountry(country, lng.LanguageCode)"
                      *ngFor="let lng of country.CountryLanguages">
                      <img [src]="'https://directscalestring.directscale.com/flags/4x3/'+country.CountryCode+'.svg'"
                        width="18"> {{country.CountryName}} | {{lng.LanguageName}}
                    </button>
                  </span>
                </mat-menu>
              </div>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </ng-template>

      <app-header (sildeMobileNavEvent)="sildeMobileNav($event)"
        *ngIf="!utilityService.isShowSimplifiedheader">
      </app-header>
  </div>



  <router-outlet></router-outlet>

  <app-footer></app-footer>

</mat-sidenav-container>
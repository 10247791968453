<div class="error sec-padding">
  <div class="container">
     <div class="page404">
        Oops! Error 404
     </div>
     <div class="not-found">
        Page Not Found
     </div>
     <div class="error_description">
       <p>We can't seem to find the page you're looking for.</p>
     </div>
     <button mat-raised-button color="primary">BACK TO HOME</button>
  </div>
</div>

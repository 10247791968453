<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="dialog-content-wrapper hosted_payment-Dialog" id="hosted_payment-Dialog-wrapper">
    <mat-toolbar class="header m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" fxFlex translate="shop_add_payment"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content id="hosted_payment-Dialog-content" fxLayout="column">
        <div class="m-b-0" *ngIf="getIframeUrl() || paymentData.Script">
            <div class="card-body p-b-5 flex-middle">
                <iframe id="iframDSPayment" [src]="iFrameUrl" width="{{getIframeWidth()}}"
                    height="{{getIframeHeight()}}" scrolling="yes" style="border:none;"></iframe>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<script>
    function OnIFrameSave(e) {

    }
</script> -->



<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon">close</mat-icon>
</button>
<div id="loader" class="loader-overlay" style="display: none;">
    <div class="loader"></div>
  </div>
<div class="dialog-content-wrapper hosted_payment-Dialog" id="hosted_payment-Dialog-wrapper">
    <mat-toolbar class="header m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" fxFlex translate="shop_add_payment"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content id="hosted_payment-Dialog-content" fxLayout="column">
        <div class="m-b-0" >
            <div class="card-body p-b-5 flex-middle">
                <iframe id="iframDSPayment" [src]="iFrameUrl" width="100%" height="{{getIframeHeight()}}" scrolling="yes" style="border:none;"></iframe>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<script>
    function OnIFrameSave(e) {

    }
    
    window.addEventListener('message', function messageListener(event) {
//   if (event.origin === iframeUrl) {
    const eventData = event.data?.data;
	const eventType = event.data?.event;

    console.log("chec the eventData",event)
	// if (eventType === ‘loaded’) {
	//   // iframe successfully loaded.
	// }

	// if (eventType === ‘formValidations’) {
	//   const jsonData = JSON.parse(eventData);
	//   if (jsonData.isFormValid) {
	//     // form is ready to submit.
	// 		}
    //   }
	//   // switch on event.data properties
    //   // (e.g. loaded, formValidations, error)
//   } 
});

</script>
<div class="faq sec-padding bgm-white">
  <div class="container">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center"  class="p-0">
        <div fxFlex="100" fxFlex.gt-sm="50" class="questions">
            <div class="faq-wrap">
                <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>What is do i have to tell you a few lorem?</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <p>
                          Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                              <span>What is do i have to tell you a few lorem?</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
                          </p>
                      </mat-expansion-panel>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span>What is do i have to tell you a few lorem?</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <p>
                              Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <span>What is do i have to tell you a few lorem?</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
                              </p>
                          </mat-expansion-panel>
                  </mat-accordion>
            </div>
        </div>

    </div>

  </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, HostListener, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { LoaderService } from "../../services/loader.service";
import { Router } from "@angular/router";
export function OnIFrameSave(data) {
  console.log(data);
  
}
export function OnIFrameError(error) {
  if (!error) {
    return;
  }
  window.alert(error);
}
@Component({
  selector: "app-hostedpayment-dialog",
  templateUrl: "./hostedpayment-dialog.component.html",
  styleUrls: ["./hostedpayment-dialog.component.scss"],
})
export class HostedpaymentDialogComponent implements OnInit {
  public paymentData: any = {};
  public iFrameUrl;
  showIsLoading: boolean = true;
  showNoProductsError: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    private loader: LoaderService,
    public router: Router,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<HostedpaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    // console.log("i frame datadata",data)
    this.iFrameUrl = data.iFrameUrl;
    this.dialogRef.disableClose = true;
  }

  


  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e.data.event == "cardSaved") {
      // e.data nexio card event
      this.dialogRef.close(e.data.data);
    } else if (e.data.isTestCardEvent == true) {
      // e.data test card event
      this.dialogRef.close(e.data);
    }
  }
  @HostListener("window:OnIFrameSave", ["$event"])
  OnIFrameSave(data) {
  console.log(data);

  }
  @HostListener("window:OnIFrameError", ["$event"])
  OnIFrameError(data) {
  console.log(data);

  }
  ngOnInit(): void {
    this.paymentData = this.data;
    this.setUpIframeMethods();
    // this.executePaymentScripts(this.paymentData.Script);
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentData.IFrameUrl,
    );
    window.addEventListener('message', this.handleIframeMessage.bind(this), false);
}
  handleIframeMessage(event: MessageEvent) {
    const iframe = document.getElementById('iframDSPayment') as HTMLIFrameElement;
    iframe.onload = () => {
     const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
     const submitButton = iframeDoc.querySelector('button[type="submit"]') as HTMLElement;
     submitButton.style.cursor = 'pointer';
   };
    console.log("events ",event)
    const loader = this.elementRef.nativeElement.querySelector('#loader');

    const eventData = event.data?.data;
    const eventType = event.data?.event; 
      if (eventType === 'submit') {
        loader.style.display = 'flex';
      }
      if(eventType==='processed')
        {
          loader.style.display = 'none';
          setTimeout(() => window['handleIframeEvent'], 3000);
        sessionStorage.setItem("customerLoginDetails", JSON.stringify(eventData))
        localStorage.setItem("customerLoginDetails", JSON.stringify(eventData))
       this.dialogRef.close(true);
       this.router.navigate(["/complete"]);
      }
  }
  cleanupCustomJS(): void {
    delete window['handleIframeEvent'];
  }

  onConfirm(data): void {
  console.log(data);
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  getIframeHeight(): string {
    if(window.innerWidth > 699) {
      return "800";
    }
    return this.paymentData.Height;
  }

  getIframeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentData.IFrameUrl,
    );
  }

  getIframeWidth(): string {
    if(window.innerWidth < 699) {
      return "300";
    }
    return this.paymentData.Width;
  }

  executePaymentScripts(html) {
    // Extract, load and execute <script src> tags
    // Extract and execute <script> tags with inline javascript

    const externalScripts = this.getExternalScripts(html);
    html = this.removeExternalScriptTags(html);

    this.runExternalScripts(externalScripts).then(() => {
      const inlineScripts = this.getInlineScripts(html);
      this.runInlineScripts(inlineScripts);
    });
  }

  getExternalScripts(html) {
    const externalScriptOpenRegex = /<script[^>]*src="[^"]*"[^>]*>/g;
    const srcAttrRegex = /src="([^"]*)"/;

    const scriptTags = html.match(externalScriptOpenRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const link = srcAttrRegex.exec(tag);

        if (!link) {
          return null;
        }

        // Index 1 is the first capture group result
        return link[1];
      })
      .filter((link) => {
        return !!link;
      });
  }

  getInlineScripts(html) {
    const inlineScriptTagRegex = /<script[^>]*>([\s\S]*)<\/script>/g;
    const scriptTags = html.match(inlineScriptTagRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const script = inlineScriptTagRegex.exec(tag);

        if (!script) {
          return null;
        }

        // Index 1 is the first capture group result
        return script[1];
      })
      .filter((script) => {
        return !!script;
      });
  }

  removeExternalScriptTags(html) {
    const externalScriptRegex = /<script[^>]*src="[^"]*"[^>]*>.*<\/script>/g;
    return html.replace(externalScriptRegex, "");
  }

  runExternalScripts(urlArray) {
    const promise = new Promise((resolve) => {
      const firstScript = urlArray.splice(0, 1)[0];
      if (!firstScript) {
        resolve(true);
        return promise;
      }

      this.http.get<any>(firstScript).subscribe(() => {
        this.runExternalScripts(urlArray);
        resolve(true);
      });
    });
    return promise;
  }
  runInlineScripts(scriptArray) {
    window.eval(scriptArray.join("\n;\n"));
  }
  setUpIframeMethods() {
    window["OnIFrameSave"] = OnIFrameSave;
    window["OnIFrameError"] = OnIFrameError;
  }
}

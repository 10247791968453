<!-- new theme section is start here -->
<div class="about-us-wrapper bgm-white">
  <div class="container">
    <!-- text section -->
    <div class="p-l-20 p-r-20 p-t-30 p-b-10" fxLayout="column" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" class="w-100">
        <hr fxFlex="60" fxFlex.gt-sm="45" fxFlex.gt-md="20" style="width: 100%; margin-bottom: 16px; color: var(--theme-default-blackish); border: 1px solid;">
      </div>
      <div fxLayout="column" class="width-responsive text-center" fxLayoutAlign="center center" fxFlex="46"
        fxFlex.lt-md="72" fxFlex.lt-sm="80">
        <p class="m-t-12 f-s-36 f-w-400 p1 ">Empowering You <br> to Live Your Best Life </p>
      </div>
    </div>
    <!-- <div class="v1">
      <div fxLayoutAlign="center center">
        <p fxFlex="100" fxFlex.gt-sm="80" fxFlex.gt-md="60" class=" v2 p2 color-blackish">Forty years ago, Loran Swensen
          embarked on a journey to help his son with
          cerebral palsy. He traveled the world learning about different therapies and talking to experts in various
          fields of study in hopes of finding ways to improve his son's quality of life. Years later, having the
          opportunity to compile all the information he learned, he brought to market a frequency software now known as
          AO
          Scan Technology. This software has been developed and improved for decades, being the most advanced frequency
          software of its kind that can help you learn how your body is performing.
        </p>
      </div>
    </div> -->

    <div class="container" fxLayoutAlign="center center">
      <div fxFlex="95" fxFlex.gt-md="80" class="m-t-30" fxLayout.lt-sm="column" fxLayout.lt-md="row"  fxLayout="row" fxLayoutGap.lt-sm="20px" fxLayoutGap="40px" fxLayoutAlign="center stretch">
        <div fxFlex="100" fxFlex.sm="46" fxFlex.md="46" fxFlex.lg="46" fxLayout="column" fxLayoutGap="40px">
          
          <div class="text-box-container">
            <span fxShow fxHide.lt-md class="v2 p2 color-blackish">
              Forty years ago, Loran Swensen
            embarked on a journey to help his son with
            cerebral palsy. He traveled the world learning about different therapies and talking to experts in various
            fields of study in hopes of finding ways to improve his son's quality of life. Years later, having the 
            </span>
            <span fxHide fxShow.lt-md class="v2 p2 color-blackish">
              Forty years ago, Loran Swensen
            embarked on a journey to help his son with
            cerebral palsy. He traveled the world learning about different therapies and talking to experts in various
            fields of study in hopes of finding ways to improve his son's quality of life. 
            </span>
          </div>
          <div>
            <img class="img-grids" src="../../../../assets/images/otherImags/DSC00697-format.jpg" alt="">
          </div>
          <div>
            <img class="img-grids" src="../../../../assets/images/otherImags/DSC00103-format.jpg" alt="">
          </div>
        </div>
        <div fxFlex="100" fxFlex.sm="54" fxFlex.md="54" fxFlex.lg="54"  fxLayout="column" fxLayoutGap="40px">
          <div class="text-box-container">
            <span fxShow fxHide.lt-md class="v2 p2 color-blackish">opportunity to compile all the information he learned, he brought to market a frequency software now known as AO Scan Technology. This software has been developed and improved for decades, being the most advanced frequency software of its kind that can help you learn how your body is performing.</span>
            <span fxHide fxShow.lt-md class="v2 p2 color-blackish">Years later, having the opportunity to compile all the information he learned, he brought to market a frequency software now known as AO Scan Technology. This software has been developed and improved for decades, being the most advanced frequency software of its kind that can help you learn how your body is performing.</span>
          </div>
          <img class="img-grids" src="../../../../assets/images/otherImags/DSC01011-Format.jpg" alt="">
        </div>
      </div>
    </div>

    <!-- image grid -->
    <!-- <div class="p-30">
      <div fxLayout="column" fxFill fxLayout.gt-sm="row" fxFlex="100" fxLayoutGap="20px">
        <div fxLayout="column wrap" fxFlex="50" fxFlex.lt-sm="100">
          <div fxLayout="row wrap" fxFlex="49" fxFlex.lt-sm="100">
            <img
              src="https://cdn.shopify.com/s/files/1/0926/9298/files/Seamless01_2x_9aae5fcf-bb1f-43a3-aa6b-d42036707085_900x.png?v=1600987079"
              alt="product-image" width="100%">
          </div>
          <div fxLayout="row wrap" class="bgm-white" fxFlex="2" fxFlex.lt-sm="100">
          </div>
          <div fxLayout="row wrap" [ngStyle.lt-md]="{'margin-top.px': 20}" fxFlex="49" fxFlex.lt-sm="100">
            <img
              src="https://cdn.shopify.com/s/files/1/0926/9298/files/IMGN_191028_AF1_sRGB91023_JUSTE_04_208_F1_sRGB_900x.jpg?v=1600985793"
              alt="product-image" width="100%">
          </div>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.lt-sm="100">
          <img
            src="https://cdn.shopify.com/s/files/1/0926/9298/files/FORCEMAJEURE_LIGHTMARINE_4_1200x1600_ecbc368d-33f9-4ae8-ac62-c2e8205511e5_900x.jpg?v=1644345376"
            alt="product-image" width="100%" height="100%">
        </div>
      </div>
    </div> -->
    <!-- text , mat-expansion -->
    <div class="p-30 m-b-20">
      <div fxLayoutAlign="center center">
        <p class="m-t-20 p2 v2 color-blackish text-center" fxFlex="100" fxFlex.gt-sm="80" fxFlex.gt-md="68"> AO Scan has
          helped millions of people discover the best version of themselves, inside
          and out, changing the way people approach their health. This has opened the doors for 5 Star to challenge the
          status quo by providing more products, including supplements, with the latest technology and the finest
          ingredients, at an affordable price.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="v4">
  <div fxLayoutAlign="center center">
    <p fxFlex="100" fxFlex.gt-sm="80" fxFlex.gt-md="40" class="p2 p-30 m-b-20 v2 color-blackish m-t-20 m-b-20">We are committed to help you live your best life by taking a new approach to wellness and putting your health back into your own hands.
    </p>
  </div>
</div>

  <!-- How It All Started -->
  <div class="container m-t-30" fxLayoutAlign="center center">
    <div fxFlex="95" fxFlex.gt-md="80" class="video-section">
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="50px" fxLayoutAlign="center stretch">
        <div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="30px">
          <div class="text-center" fxLayoutAlign="center center">How It All Started </div>
          <div class="iframe-wrapper">
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/ZJi5-xRvWNk?si=oN9DQCSdiD4Dytk_"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="30px">
          <div class="text-center" fxLayoutAlign="center center">The Future of <br> Wellness Is Here </div>
          <div class="iframe-wrapper">
          <iframe width="100%" height="auto" src="https://www.youtube.com/embed/DVlWKQVskoc?si=dZiMmhjZh3tiVOiF"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Products -->
  <div class="container m-t-30 m-b-30" fxLayoutAlign="center center">
    <div fxFlex="95" fxFlex.gt-md="75" class="product-images-section m-b-30">
      <div fxLayout="column">
        <div class="m-t-10" fxLayout="column" fxFill fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
          fxLayoutGap="20px" fxFlex="100">
          <div fxLayout="column" fxLayout.gt-sm="row"  fxLayoutGap="30px" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <img src="../../../../assets/images/otherImags/Future is Frequency.webp" alt="" class="img-f">
              <div class="title-text">AO SCAN</div>
              <div class="bttn">
                <button class="btn-big" [routerLink]="'/products/all'" [queryParams]="{ searchtext: 'ao scan' }">SHOP</button>
              </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <img src="../../../../assets/images/bundle-images/BuilderBluetoothBundle.jpg" alt="" class="img-f">
              <div class="title-text">BUNDLES</div>
              <div class="bttn" >

                <button class="btn-big" [routerLink]="'/products/3'">SHOP</button>
              </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxLayoutAlign="center" fxFill>
              <img src="../../../../assets/images/otherImags/LightwaveGlasses.png" alt="" class="img-f">
              <div class="title-text">SHOP ALL</div>
              <div class="bttn">

                <button class="btn-big" routerLink="/products/all">SHOP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
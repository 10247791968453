
<div fxLayoutAlign="end center" class="m-r-15 cursor-pointer">
    <mat-icon class="close-icon color-blackish" (click)="close()">close</mat-icon>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="m-b-20">
    <div class="text-center f-s-22 f-w-500 modal-title">LOYALTY PROGRAM</div>
    <hr style="border: 1px solid gray; width: 180px;" />
</div>

<div class="p-30">
    <!-- 1st Video -->
    <div fxLayout="column" fxLayout.gt-sm="row" class="m-b-30" fxLayoutGap="20px">
        <div fxFlex="100" fxFlex.gt-sm="40" class="">
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/arWnD3Hwzy8?si=3FLlwFcx7powYtOh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="60">
            <p class="m-b-20">After successfully processing three automatic orders, QLAs and PCs will receive a 30% off coupon that can be applied to one item in the back office*. The discount expires 30 days after the issued date.  </p>

            <p>This simple Loyalty Program rewards all QLAs and PCs, at no additional entry costs or requirements.
            </p>
        </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" class="" fxLayoutGap="20px">
        <div fxFlex="100" fxFlex.gt-sm="55" class="how-to-sign-up">
            <div class="text-uppercase m-b-15">How do I sign up? </div>
            <ul class="m-b-15">
                <li>Find your favorite products </li>
                <li>Select “Subscribe & Save” when adding your products to your cart.  </li>
                <li>Checkout! </li>
            </ul>
            <p class="m-b-15">It really is as simple as that! After processing your autoship for three consecutive months you’ll receive your discount. </p>
            <div class="m-t-10" fxLayoutAlign="center center">
                <button routerLink="/products/all" (click)="close()" class="shop-btn-global m-t-20">Start Now</button>
            </div>

        </div>
        <div fxFlex="100" fxFlex.gt-sm="45" class="p-r-15 p-l-15">
            <img src="../../../../../assets/images/lifestyle-images/DSC09837-format.jpg" alt="" class="img-fluid">
        </div>
        
    </div>
</div>

<h1 mat-dialog-title class="color-blackish">
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p class="color-blackish">{{message}}</p>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" *ngIf="noaction" class="color-blackish">{{noaction}}</button>
    <button mat-raised-button color="primary" *ngIf="takeaction" (click)="onConfirm()" class="agreeBtn">{{takeaction}}</button>

  </div>
<div class="wrapper">

    <div fxLayout="column">
        <!-- Close -->
        <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b-20">
            <p class="f-w-700 f-s-20">Choose Variant</p>
            <button mat-raised-button (click)="dialogRef.close()" fxLayoutAlign="center center">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <!-- Contentainer -->
        <div class="p-20">
            <ng-container *ngFor="let option of product?.ItemOptions; let i = index">               
                <div class="m-0 p-20-0 custom-size checkoptionbox">
                   <p class="f-w-500 text">Choose {{option.Option}}</p>
                   <mat-form-field appearance="outline" class="w-100">
                      <mat-select readonly="true" [(ngModel)]="value[i]"
                         (selectionChange)="checkOptions(product?.ItemOptions);" name="value.Option"
                         placeholder="Select {{option.Option}}" (click)="openSelect($event)"
                         class="md-no-underline-select">
                         <mat-option
                            *ngFor="let validValue of getValidValues(product, option, option.Values)"
                            (ngInit)="value[i] = getValidValues(product, option, option.Values)[0].Option" [value]="validValue.Option"
                            (click)="validValue.isAvailable && selectOption(product, option, validValue, false)">
                            {{validValue.Option}} </mat-option>
                      </mat-select>
                   </mat-form-field>
                </div>
             </ng-container>
        </div>
        <!-- Add to cart -->
        <div fxLayoutAlign="center center">
            <div class="btn-atc" (click)="addToCart(product)">
                Add to cart
            </div>

        </div>
    </div>

</div>